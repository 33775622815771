import "./overview-style.css";
import PhoneFrame from "../phoneFrame/PhoneFrame";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const Overview = () => {
  const { t } = useTranslation();
  const [displaySounds, setDisplaySounds] = useState(1);
  const soundDetailsArray = [
    {
      id: 0,
      title: `${t("defaultSounds")}`,
      content: `${t("defaultSoundsText")}`,
      icon: "fas fa-volume-up",
      numSound: 1,
    },
    {
      id: 1,
      title: `${t("intermedSounds")}`,
      content: `${t("intermedSoundsText")}`,
      icon: "fab fa-itunes-note",
      numSound: 2,
    },
    {
      id: 2,
      title: `${t("allOtherSounds")}`,
      content: `${t("otherSoundsText")}`,
      icon: "fas fa-signal",
      numSound: 3,
    },
  ];

  useEffect(() => {
    // console.log("Sound displayed", displaySounds);
  }, [displaySounds]);
  return (
    <section id="overview" className="vc_section bg-grey">
      <div className="overview-first-section">
        <figure className="wpb_wrapper vc_figure wrapperPhone1">
          <div
            data-inviewport="scaleUp"
            className="banner-image overviewBanner"
          >
            <div className="phoneFrame-overview">
              <PhoneFrame displaySounds={displaySounds} />
            </div>
            <div className=" phoneFrame-overview1">
              <PhoneFrame displaySounds={displaySounds} />
            </div>
          </div>
        </figure>

        <div className="overview-item">
          <div className="section-title-overview-first ">
            <h3>{t("newSoundsTitle")}</h3>
            <p className="overviewParag overviewParag-first">
              {t("newSoundsText")}
            </p>
          </div>
          <div className="contentSecondSection">
            {soundDetailsArray.map((res) => (
              <div
                onClick={() => setDisplaySounds(res.numSound)}
                key={res.id}
                className={
                  displaySounds === res.numSound
                    ? "overview-box overview-box--active d-flex flex-wrap"
                    : "overview-box d-flex flex-wrap"
                }
              >
                <div className="icon icon-basic-compass">
                  <i className={`${res.icon}`}></i>
                </div>
                <div className="content second">
                  <h6 className="font-weight-bold mb-2 mt-0">{res.title}</h6>
                  <p>{res.content}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="wpb_wrapper">
        <div className="vc_empty_space" style={{ height: 100 }}>
          <span className="vc_empty_space_inner"></span>
        </div>
      </div>

      <div className="overview-second-section">
        <div className="wpb_wrapper left">
          <div className="section-title-overview ">
            <h3 className="titleSecondSection">{t("goodKnowTitle")}!</h3>
            <p className="overviewParag-second">{t("goodKnowSub")}</p>
          </div>
          <div className="wpb_text_column wpb_content_element  overview-list">
            <ul className="wpb_wrapper secondOverviewlist">
              <li>
                <i className="fas fa-check"></i> <p>{t("goodKnowText1")}</p>
              </li>
              <li>
                <i className="fas fa-check"></i> <p>{t("goodKnowText2")}</p>
              </li>
              <li>
                <i className="fas fa-check"></i>
                <p>{t("goodKnowText3")}</p>
              </li>
              <li>
                <i className="fas fa-check"></i> <p>{t("goodKnowText4")}</p>
              </li>
              <li>
                <i className="fas fa-check"></i> <p>{t("goodKnowText5")}</p>
              </li>
              <li>
                <i className="fas fa-check"></i>
                <p>{t("goodKnowText9")}</p>
              </li>
              <li>
                <i className="fas fa-check"></i>
                <p>{t("goodKnowText6")}</p>
              </li>
              <li>
                <i className="fas fa-check"></i>
                <p>{t("goodKnowText7")}</p>
              </li>
              <li>
                <i className="fas fa-check"></i>
                <p>{t("goodKnowText8")}</p>
              </li>
            </ul>
          </div>
        </div>

        <figure
          data-inviewport="scaleUp"
          className="wpb_wrapper vc_figure lastFigure"
        >
          <div className="vc_single_image-wrapper mobileresponsive  vc_box_border_grey">
            <div className="phoneFrame-overview-bottom">
              <PhoneFrame />
            </div>
            <div className=" phoneFrame-overview1-bottom">
              <PhoneFrame />
            </div>
          </div>
        </figure>
      </div>
    </section>
  );
};

export default Overview;

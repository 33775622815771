import { useLayoutEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";

import "./counters-style.css";

const Counters = () => {
  const { t } = useTranslation();
  const [countSounds] = useState(42);
  const [countLevels] = useState(4);
  const [countUsers] = useState(4275);
  const [countTips] = useState(21);
  function counter(id, start, end, duration) {
    let obj = document.getElementById(id),
      current = start,
      range = end - start,
      increment = end > start ? 1 : -1,
      step = Math.abs(Math.floor(duration / range)),
      timer = setInterval(() => {
        current += increment;
        obj.textContent = current;
        if (current === end) {
          clearInterval(timer);
        }
      }, step);
  }

  useLayoutEffect(() => {
    function isInViewport(el) {
      const rect = el.getBoundingClientRect();
      return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <=
          (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <=
          (window.innerWidth || document.documentElement.clientWidth)
      );
    }

    const box = document.querySelector(".box");

    document.addEventListener(
      "scroll",
      function () {
        if (isInViewport(box) && sessionStorage.getItem("viewCount") === null) {
          counter("count1", 0, countSounds, 3500);
          counter("count2", 0, countLevels, 3500);
          counter("count3", 500, countUsers, 500);
          counter("count4", 0, countTips, 3500);
          sessionStorage.setItem("viewCount", "yes");
        }
        if (
          !isInViewport(box) &&
          sessionStorage.getItem("viewCount") !== null
        ) {
          sessionStorage.removeItem("viewCount");
        }
      },
      {
        passive: true,
      }
    );
  }, [countSounds, countLevels, countUsers, countTips]);

  return (
    <section
      id="counters"
      className="vc_section vc_custom_1622205278038 parallax"
    >
      <div className="overlay"></div>
      <div className="container-counter">
        <div className="wpb_column vc_column_container vc_col-sm-3">
          <div className="vc_column-inner">
            <div className="wpb_wrapper count-grid">
              <div
                className="counter wow fadeInUp"
                data-wow-delay="0s"
                style={{
                  visibility: "visible",
                  animationDelay: "0s",
                  animationName: "fadeInUp",
                }}
              >
                <div className="icon icon-basic-download">
                  <i className="fas fa-volume-down"></i>
                </div>
                <div className="counter-content res-margin">
                  <h5>
                    <span id="count1" className="number-count">
                      {sessionStorage.getItem("viewCount") !== null
                        ? countSounds
                        : null}
                    </span>
                  </h5>
                  {isMobile ? (
                    <p>{t("audioSoundMobile")}</p>
                  ) : (
                    <p>{t("audioSoundDesktop")}</p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="wpb_column vc_column_container vc_col-sm-3">
          <div className="vc_column-inner">
            <div className="wpb_wrapper count-grid">
              <div className="box" />

              <div
                className="counter wow fadeInUp"
                data-wow-delay="0.3s"
                style={{
                  visibility: "visible",
                  animationDelay: "0.3s",
                  animationName: "fadeInUp",
                }}
              >
                <div className="icon icon-ecommerce-bag-plus">
                  <i className="fas fa-shield-alt"></i>
                </div>
                <div className="counter-content res-margin">
                  <h5>
                    <span id="count2" className="number-count-niveaux">
                      {sessionStorage.getItem("viewCount") !== null
                        ? countLevels
                        : null}
                    </span>
                  </h5>
                  {isMobile ? (
                    <p>{t("levelsCount")}</p>
                  ) : (
                    <p>{t("levelsCountDesktop")}</p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="wpb_column vc_column_container vc_col-sm-3">
          <div className="vc_column-inner">
            <div className="wpb_wrapper count-grid">
              <div
                className="counter wow fadeInUp"
                data-wow-delay="0.6s"
                style={{
                  visibility: "visible",
                  animationDelay: "0.6s",
                  animationName: "fadeInUp",
                }}
              >
                <div className="icon icon-basic-tablet">
                  <i className="fas fa-user-clock"></i>
                </div>
                <div className="counter-content res-margin">
                  <h5>
                    <span id="count3" className="number-count-users">
                      {" "}
                      {sessionStorage.getItem("viewCount") !== null
                        ? countUsers
                        : null}
                    </span>
                  </h5>
                  {isMobile ? (
                    <p>{t("usersCount")}</p>
                  ) : (
                    <p>{t("usersCountDesktop")}</p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="wpb_column vc_column_container vc_col-sm-3">
          <div className="vc_column-inner">
            <div className="wpb_wrapper count-grid">
              <div
                className="counter wow fadeInUp"
                data-wow-delay="0.9s"
                style={{
                  visibility: "visible",
                  animationDelay: "0.9s",
                  animationName: "fadeInUp",
                }}
              >
                <div className="icon icon-basic-star">
                  <i className="fas fa-hand-holding-heart"></i>
                </div>
                <div className="counter-content res-margin">
                  <h5>
                    <span id="count4" className="number-count-health">
                      {" "}
                      {sessionStorage.getItem("viewCount") !== null
                        ? countTips
                        : null}
                    </span>
                  </h5>
                  {isMobile ? (
                    <p>{t("advicesCount")}</p>
                  ) : (
                    <p>{t("advicesCountDesktop")}</p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Counters;

import "./App.css";

import { Routes, Route } from "react-router-dom";
import Lightcase from "./components/lightcase/Lightcase";
import HomePage from "./components/homePage/HomePage";
import { useEffect } from "react";
import LoadingApp from "./components/loadingApp/LoadingApp";

import { useSafeState } from "./hooks/useSafeState";
import FooterGame from "./components/footer/footerGame/FooterGame";
import { isMobile } from "react-device-detect";
import ErrorPageMobile from "./components/errorPageMobile/ErrorPageMobile";

function App() {
  const [isLoading, setIsLoading] = useSafeState(
    sessionStorage.getItem("loading") === null ? true : false
  );

  useEffect(() => {
    if (isLoading) {
      sessionStorage.setItem("loading", true);
      setTimeout(() => {
        setIsLoading(false);
      }, 8000);
    }
    return () => {
      clearTimeout(setTimeout);
    };
  }, [isLoading, setIsLoading]);
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={isLoading ? <LoadingApp /> : <HomePage />} />
        <Route path="/light" element={<Lightcase />} />
        <Route
          path="*"
          element={!isMobile ? <FooterGame /> : <ErrorPageMobile />}
        />
      </Routes>
    </div>
  );
}

export default App;

import "./teamAnimated-style.css";
import Menber1 from "../../../assets/me.jpg";
import { useTranslation } from "react-i18next";

const TeamAnimated = () => {
  const { t } = useTranslation();
  return (
    <div className="containerCard">
      <div className="card">
        <div className="imgBx">
          <img
            className="pictMe"
            loading="lazy"
            src={Menber1}
            alt="rodolphe Augusto"
          />
        </div>
        <div className="cardContent">
          <div className="cardDetails">
            <h2>
              Rodolphe Augusto
              <br />
              <span>{t("devSubName")} (.org, .fr et .us)</span>
            </h2>
            <ul className="social_icons">
              <li>
                <a
                  href="https://www.facebook.com/rodolphe.augusto.7"
                  target="new"
                >
                  <i className="fab fa-facebook-f" aria-hidden="true"></i>
                </a>
              </li>
              <li>
                <a
                  href="https://www.instagram.com/rodolphelakotaspirit/"
                  target="new"
                >
                  <i className="fab fa-instagram" aria-hidden="true"></i>
                </a>
              </li>
              <li>
                <a
                  href="https://www.linkedin.com/in/rodolphe-augusto-auteur-d%C3%A9veloppeur-fullstack-react-node-js-1a20b759/"
                  target="new"
                >
                  <i className="fab fa-linkedin" aria-hidden="true"></i>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TeamAnimated;

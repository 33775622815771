import { useTranslation } from "react-i18next";
import "./serviceCards-style.css";

const ServiceCards = () => {
  const { t } = useTranslation();
  return (
    <div className="cardsContainer">
      <div data-inviewport="fadeInUp" className="card">
        <div className="icon">
          <i className="fas fa-shield-alt"></i>
        </div>
        <div className="contentCard">
          <h5>{t("levelDetails")}</h5>
          <p>{t("levelDetailsText")}</p>
        </div>
      </div>
      <div data-inviewport="fadeInUpMediumDelay" className="card">
        <div className="icon">
          <i className="fas fa-images"></i>
        </div>
        <div className="contentCard">
          <h5>{t("moreDetailsLogo")}</h5>
          <p>{t("moreDetailsLogoText")}</p>
        </div>
      </div>
      <div data-inviewport="fadeInUpLargeDelay" className="card">
        <div className="icon">
          <i className="fas fa-clipboard-list"></i>
        </div>
        <div className="contentCard">
          <h5>{t("moreDetailsHistory")}</h5>
          <p>{t("moreDetailsHistoryText")}</p>
        </div>
      </div>
    </div>
  );
};

export default ServiceCards;

import { useTranslation } from "react-i18next";
import "./pricing3d-style.css";

const Pricing3D = () => {
  const { t, i18n } = useTranslation();
  return (
    <div className="container mt30">
      <div className="wrapper">
        <ul className="stage clearfix">
          <li className={i18n.language === "fr" ? "scene" : "scene sceneUs"}>
            <div className="movie">
              <div className="poster">
                <div className="card-ribbon">
                  <span>{t("comming")}</span>
                </div>
                <i className="fas fa-spa"></i>
              </div>
              <div className="info">
                <header>
                  <i className="fas fa-spa"></i>
                  <h1>{t("learningCard")}</h1>

                  <div className="blockHeader">
                    <span className="year">{t("mensuel")}</span>
                    <span className="rating">X €</span>
                    <span className="duration">{t("permouth")}</span>
                  </div>
                </header>
                <p className="parag">
                  <strong>{t("optionSoon")}</strong>
                  <br />
                  {t("learnText")}
                </p>
                <p className="mb-0">
                  <a className="btn" href="#subscribe">
                    {t("sign")}
                  </a>
                </p>
              </div>
            </div>
          </li>

          <li className={i18n.language === "fr" ? "scene" : "scene sceneUs"}>
            <div className="movie">
              <div className="poster">
                <i className="fas fa-yin-yang"></i>
              </div>
              <div className="info">
                <header>
                  <i className="fas fa-yin-yang"></i>
                  <h1>{t("auto")}</h1>
                  <div className="blockHeader">
                    <span className="year">{t("freeApp")}</span>
                    <span className="rating">0 €</span>
                    <span className="duration">{t("permouth")}</span>
                  </div>
                </header>
                <p className="parag">
                  <strong>{t("freeMess")}</strong> <br />
                  {t("freemessText")}
                </p>
                <p className="mb-0">
                  <a
                    className="btn"
                    href={
                      i18n.language === "fr"
                        ? "https://my-meditation-time.fr"
                        : "https://my-meditation-time.us"
                    }
                    target="new"
                  >
                    {t("try")}
                  </a>
                </p>
              </div>
            </div>
          </li>

          <li className={i18n.language === "fr" ? "scene" : "scene sceneUs"}>
            <div className="movie">
              <div className="poster">
                <i className="fas fa-om"></i>
                <div className="card-ribbon">
                  <span>{t("comming")}</span>
                </div>
              </div>
              <div className="info">
                <header>
                  <i className="fas fa-om"></i>
                  <h1>{t("teacher")}</h1>
                  <div className="blockHeader">
                    <span className="year">{t("mensuel")}</span>
                    <span className="rating">%</span>
                    <span className="duration">{t("permouth")}</span>
                  </div>
                </header>
                <p className="parag">
                  <strong> {t("optionSoon")}</strong> <br />
                  {t("teacherText")}
                </p>
                <p className="mb-0">
                  <a className="btn" href="#subscribe">
                    {t("sign")}
                  </a>
                </p>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Pricing3D;

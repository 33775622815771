import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "./animeFeatures-style.css";

const AnimeFeatures = () => {
  const { t } = useTranslation();
  const [clickedOptions, setClickedOptions] = useState({ option: "profile" });

  useEffect(() => {
    const pict = document.getElementById("features2b");
    if (clickedOptions.option === "profile") {
      pict.classList.remove("shareOption");
      pict.classList.remove("inviteOption");
    }
    if (clickedOptions.option === "share") {
      pict.classList.add("shareOption");
      pict.classList.remove("inviteOption");
    }
    if (clickedOptions.option === "invite") {
      pict.classList.remove("shareOption");
      pict.classList.add("inviteOption");
    }
    console.log("clickedOptions", clickedOptions);
  }, [clickedOptions]);
  return (
    <div className="animeFeaturesBlockContainer">
      <div className="animFeaturesContainer">
        <div
          onClick={() => setClickedOptions({ option: "profile" })}
          className="service-box"
        >
          <div className="animFeatureIcon" style={{ background: "var(--i)" }}>
            <i className="far fa-user-circle"></i>
          </div>
          <div className="animeFeaturesContent">
            <h4>{t("profileSub1")}</h4>
            <p>
              <strong>{t("profileSub1Text1")}</strong>
              <br /> {t("profileSub1Text2")}
              <br /> {t("profileSub1Text3")}
            </p>
          </div>
        </div>
        <div
          onClick={() => setClickedOptions({ option: "share" })}
          className="service-box"
        >
          <div className="animFeatureIcon" style={{ background: "var(--i)" }}>
            <i className="fas fa-download"></i>
          </div>
          <div className="animeFeaturesContent">
            <h4>{t("sharePictTitle")} !</h4>
            <p>
              <strong>{t("sharePictSub")}</strong>
              <br /> {t("sharePictText1")}
              <br /> {t("sharePictText2")}
            </p>
          </div>
        </div>
        <div
          onClick={() => setClickedOptions({ option: "invite" })}
          className="service-box"
        >
          <div className="animFeatureIcon" style={{ background: "var(--i)" }}>
            <i className="far fa-comment"></i>
          </div>
          <div className="animeFeaturesContent">
            {" "}
            <h4>{t("inviteTitle")}!</h4>
            <p>
              <strong>{t("inviteSub")}</strong>
              <br /> {t("inviteText")}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AnimeFeatures;

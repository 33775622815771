import { useTranslation } from "react-i18next";
import ServiceCards from "./serviceCards/ServiceCards";
import "./services-style.css";

const Services = () => {
  const { t } = useTranslation();
  return (
    <section id="services" className="services">
      <div className="container-services">
        <div className="vc_row wpb_row vc_row-fluid">
          <div className="service-wrapper">
            <div className="section-title text-center  ">
              <h3>{t("moreDetailsTitle")}!</h3>
              <p>{t("moreDetailsSub")} !</p>
            </div>
          </div>
        </div>
        <ServiceCards />
        {/*<div className="services-card">
          <div
            data-inviewport="fadeInUp"
            className="service-single  res-margin wow"
          >
            <div className="icon icon-basic-server2">
              <i className="fas fa-shield-alt"></i>
            </div>
            <h5>{t("levelDetails")}</h5>
            <p>{t("levelDetailsText")}</p>
          </div>
          <div
            data-inviewport="fadeInUpMediumDelay"
            className="service-single  res-margin wow "
          >
            <div className="icon icon-basic-headset">
              {" "}
              <i className="fas fa-images"></i>
            </div>
            <h5>{t("moreDetailsLogo")}</h5>
            <p>{t("moreDetailsLogoText")}</p>
          </div>
          <div
            data-inviewport="fadeInUpLargeDelay"
            className="service-single  res-margin wow "
            style={{
              visibility: "visible",
            }}
          >
            <div className="icon icon-om">
              <i className="fas fa-clipboard-list"></i>
            </div>
            <h5>{t("moreDetailsHistory")}</h5>
            <p>{t("moreDetailsHistoryText")}</p>
          </div>
          </div>*/}
      </div>
    </section>
  );
};

export default Services;

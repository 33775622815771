import { useState } from "react";
import TranslationButtons from "../header/translationButtons/TranslationButtons";
import "./blockSetting-style.css";

const BlockSettings = () => {
  const [clickedBlockSettings, setClickedBlockSettings] = useState(false);

  const handleClickBlockSettings = () => {
    setClickedBlockSettings((clickedBlockSettings) => !clickedBlockSettings);
  };

  return (
    <div
      className={
        clickedBlockSettings
          ? "block-settings-wrapper opened-settings"
          : "block-settings-wrapper"
      }
    >
      <div id="block_settings" className="block_settings">
        <section>
          <TranslationButtons
            setClickedBlockSettings={setClickedBlockSettings}
          />
        </section>
        <span onClick={handleClickBlockSettings} id="settings_close"></span>
      </div>
    </div>
  );
};

export default BlockSettings;

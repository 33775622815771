import "./socialMedia-style.css";

const SocialMedia = () => {
  return (
    <div className="socialMedia">
      <ul className="sci">
        <li>
          <a
            href="https://www.facebook.com/meditationwithamanohilot"
            target="new"
          >
            <i className="fab fa-facebook-f"></i>
          </a>
        </li>
        <li>
          <a
            href="https://www.instagram.com/rodolphelakotaspirit/"
            target="new"
          >
            <i className="fab fa-instagram"></i>
          </a>
        </li>
        <li>
          <a
            href="https://www.linkedin.com/in/rodolphe-augusto-auteur-d%C3%A9veloppeur-fullstack-react-node-js-1a20b759/"
            target="new"
          >
            <i className="fab fa-linkedin"></i>
          </a>
        </li>
        <li>
          <a
            href="https://www.pinterest.fr/rodolpheaugusto/_saved/"
            target="new"
          >
            <i className="fab fa-pinterest"></i>
          </a>
        </li>
      </ul>
    </div>
  );
};

export default SocialMedia;

import { useTranslation } from "react-i18next";
import "./latestPosts-style.css";

const LatestPosts = () => {
  const { t } = useTranslation();
  return (
    <section id="blog" className="vc_section">
      <div className="container-blog">
        <div className="section-title text-center  ">
          <h3>{t("publicationTitle")}</h3>
          <p>{t("publiSub")}</p>
        </div>

        <div className=" blog-home">
          <div data-inviewport="fadeInUp" className="blog-col">
            <iframe
              loading="lazy"
              title="latest Publication"
              src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fmeditationwithamanohilot%2Fposts%2F102654788902438&show_text=true&width=500"
              width="500"
              height="771"
              style={{ border: "none", overflow: "hidden" }}
              scrolling="no"
              frameBorder="0"
              allowFullScreen={true}
              allow="autoplay; clipboard-write; encrypted-media; picture-in-picture"
            ></iframe>
            <div className="LinkBlog">
              <a
                href="https://www.facebook.com/meditationwithamanohilot%2Fposts%2F102654788902438&show_text=true"
                target="new"
              >
                <span>
                  <i className="fab fa-facebook-f"></i>
                </span>
              </a>
            </div>
          </div>

          <div data-inviewport="fadeInUpMediumDelay" className="blog-col">
            <iframe
              loading="lazy"
              title="latest Publication"
              src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fmeditationwithamanohilot%2Fposts%2F102658878902029&show_text=true&width=500"
              width="500"
              height="771"
              style={{ border: "none", overflow: "hidden" }}
              scrolling="no"
              frameBorder="0"
              allowFullScreen={true}
              allow="autoplay; clipboard-write; encrypted-media; picture-in-picture"
            ></iframe>
            <div className="LinkBlog">
              <a
                href="https://www.facebook.com/meditationwithamanohilot%2Fposts%2F102658878902029&show_text=true"
                target="new"
              >
                <span>
                  <i className="fab fa-facebook-f"></i>
                </span>
              </a>
            </div>
          </div>

          <div data-inviewport="fadeInUpLargeDelay" className="blog-col">
            <iframe
              loading="lazy"
              title="Latest Publication"
              src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fmeditationwithamanohilot%2Fposts%2F102654462235804&show_text=true&width=500"
              width="500"
              height="783"
              style={{ border: "none", overflow: "hidden" }}
              scrolling="no"
              frameBorder="0"
              allowFullScreen={true}
              allow="autoplay; clipboard-write; encrypted-media; picture-in-picture"
            ></iframe>
            <div className="LinkBlog">
              <a
                href="https://www.facebook.com/meditationwithamanohilot%2Fposts%2F102654462235804&show_text=true"
                target="new"
              >
                <span>
                  <i className="fab fa-facebook-f"></i>
                </span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default LatestPosts;

import { useTranslation } from "react-i18next";
import "./errorPageMobile-style.css";

const ErrorPageMobile = () => {
  const { t } = useTranslation();
  return (
    <section class="page_404">
      <div class="container">
        <div class="row">
          <div class="col-sm-12 ">
            <div class="col-sm-10 col-sm-offset-1  text-center">
              <div class="four_zero_four_bg">
                <h1 class="text-center ">404</h1>
              </div>

              <div class="contant_box_404">
                <h3 class="h2">{t("lost")}</h3>

                <p>{t("notAvailable")}</p>

                <a href="/" class="link_404">
                  {t("goHome")}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ErrorPageMobile;

import "./contact-style.css";
import { useForm } from "react-hook-form";
import { useState } from "react";
import emailjs from "emailjs-com";
import { useTranslation } from "react-i18next";

const Contact = () => {
  const { t, i18n } = useTranslation();
  const { register, handleSubmit } = useForm();
  const [messageSendResult, setMessageSendResult] = useState(undefined);

  const ReloadForm = () => {
    setTimeout(() => {
      window.location.reload();
    }, 2500);
  };

  const ResetInput = () => {
    setTimeout(() => {
      document.getElementById("contact-form").reset();
    }, 2000);
  };

  const onSubmit = (data) => {
    emailjs
      .send("gmail", "template_wQIJppFE", data, "user_xKWRvlBTc0uT65Tf9D7wJ")
      .then(
        function (response) {
          console.log("SUCCESS!", response.status, response.text);
          ResetInput();
          setMessageSendResult(
            <p className="messageResult-ok">
              {response.text}, {t("sendok")}
            </p>
          );

          setTimeout(() => {
            ReloadForm();
          }, 250);
          ReloadForm();
        },
        function (error) {
          console.log("FAILED...", error);
          setMessageSendResult(
            <p className="messageResult-error">{t("errornotsend")}</p>
          );

          ResetInput();
          setTimeout(() => {
            ReloadForm();
          }, 250);
        }
      );
  };
  return (
    <section id="contact" className="vc_section">
      <div className="container">
        <div className="vc_row wpb_row vc_row-fluid">
          <div className="wpb_column vc_column_container vc_col-sm-12">
            <div className="vc_column-inner">
              <div className="wpb_wrapper">
                <div className="row justify-content-center">
                  <div className="col-12 col-md-10 col-lg-6 headContact">
                    <div className="section-title text-center  ">
                      <h3>{t("contactTitle")}</h3>
                      <p>{t("allfields")}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="vc_row wpb_row vc_row-fluid contact-container">
          <div className="wpb_column vc_column_container contact-info-container">
            <div className="contact-info res-margin">
              <h5>
                <span className="icon icon-basic-geolocalize-05"></span>
                My Meditation Time
              </h5>
              <p>Tours (37)</p>

              <h5>
                <span className="icon icon-basic-clock"></span> {t("working")}
              </h5>
              <p>09:00 à 18:00</p>
              <h5>{t("moreInfo")}</h5>
              <p>
                <a href="https://amano-hilot.fr" target="new">
                  Amano'Hilot.fr
                </a>
              </p>
              <p>
                <a href="https://rodolphe-augusto.fr" target="new">
                  Rodolphe Augusto.fr
                </a>
              </p>
            </div>
          </div>
          <div className="wpb_column vc_column_container vc_col-sm-8 form-container">
            <div
              role="form"
              className="wpcf7"
              id="wpcf7-f131-p61-o1"
              lang="en-US"
              dir="ltr"
            >
              <div className="screen-reader-response">
                <p role="status" aria-live="polite" aria-atomic="true"></p>
                <ul></ul>
              </div>
              <div className="messageResult">{messageSendResult}</div>
              <form
                id="contact-form"
                onSubmit={handleSubmit(onSubmit)}
                className="wpcf7-form init"
              >
                <div className="row first-line">
                  <span className="wpcf7-form-control-wrap your-name contact-col-first">
                    <input
                      className="wpcf7-form-control wpcf7-text wpcf7-email wpcf7-validates-as-required wpcf7-validates-as-email form-control"
                      {...register("nom")}
                      placeholder={
                        i18n.language === "fr"
                          ? "Nom et prénom"
                          : "First & Last names"
                      }
                      required
                    />
                  </span>

                  <span className="wpcf7-form-control-wrap your-email contact-col-first">
                    <input
                      className="wpcf7-form-control wpcf7-text wpcf7-email wpcf7-validates-as-required wpcf7-validates-as-email form-control"
                      {...register("email")}
                      placeholder={
                        i18n.language === "fr" ? "Votre Email" : "Your Email"
                      }
                      required
                    />
                  </span>
                </div>
                <div className="row subject">
                  <div className="col-12 col-lg-12">
                    <div className="form-group mt-2">
                      <span className="wpcf7-form-control-wrap your-subject">
                        <input
                          {...register("objet")}
                          placeholder={
                            i18n.language === "fr"
                              ? "Objet du message"
                              : "Object message"
                          }
                          className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required form-control"
                          required
                        />
                      </span>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 col-lg-12">
                    <div className="form-group mt-2">
                      <span className="wpcf7-form-control-wrap your-message">
                        <textarea
                          cols="40"
                          rows="10"
                          className="wpcf7-form-control wpcf7-textarea form-control"
                          {...register("texte")}
                          placeholder={
                            i18n.language === "fr"
                              ? "Ecrivez votre message ici"
                              : "Write your message here"
                          }
                          required
                        ></textarea>
                      </span>
                    </div>
                  </div>
                </div>
                <div className="row buttonForm">
                  <button
                    type="submit"
                    className="wpcf7-form-control has-spinner wpcf7-submit btn"
                  >
                    {t("sendMess")}
                  </button>
                  <span className="wpcf7-spinner"></span>
                </div>

                <div className="wpcf7-response-output" aria-hidden="true"></div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;

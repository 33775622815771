import "./splashScreenPhone-style.css";

const SplashScreenPhone = () => {
  return (
    <div className="container-splash">
      <div className="capsule one  ">
        <div className="dot-splash"></div>
      </div>
      <div className="capsule two  ">
        <div className="dot-splash"></div>
      </div>
      <div className="capsule three">
        <div className="dot-splash"></div>
      </div>
      <div className="capsule four ">
        <div className="dot-splash"></div>
      </div>
      <div className="capsule five ">
        <div className="dot-splash"></div>
      </div>
    </div>
  );
};

export default SplashScreenPhone;

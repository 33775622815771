import "./annonceMobile-style.css";
import AttendanceIcon from "../../../assets/annonces/attendance-home.png";
import BeginnerIcon from "../../../assets/annonces/beginner.png";
import IntermedIcon from "../../../assets/annonces/intermediate.png";
import ConfirmedIcon from "../../../assets/annonces/confirmed.png";
import ExpertIcon from "../../../assets/annonces/expert.png";
import BestLearnIcon from "../../../assets/annonces/bestLearner-opt.png";
import CuriousIcon from "../../../assets/annonces/curiousBadge-opt.png";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const AnnoncesMobile = () => {
  const { t } = useTranslation();
  const [idSlide, setIdSlide] = useState(0);

  useEffect(() => {
    if (idSlide > 6) {
      setIdSlide(0);
    }
    if (idSlide === -1) {
      setIdSlide(6);
    }

    // console.log("Id Slide:", idSlide);
  }, [idSlide]);

  const nextSlide = () => {
    setIdSlide((idSlide) => idSlide + 1);
  };
  const prevSlide = () => {
    setIdSlide((idSlide) => idSlide - 1);
  };

  useEffect(() => {
    const autoSlide = setInterval(() => {
      nextSlide();
      if (idSlide > 6) {
        setIdSlide(0);
      }
      if (idSlide === -1) {
        setIdSlide(6);
      }
    }, 4500);
    return () => {
      clearInterval(autoSlide);
    };
  }, [idSlide]);

  const BadgesList = [
    {
      id: 0,
      name: "beginner",
      logo: BeginnerIcon,
      num: 1,
      text: `${t("beginBadge")}`,
      width: "150px",
    },
    {
      id: 1,
      name: "intermediate",
      logo: IntermedIcon,
      num: 2,
      text: `${t("intermBadge")}`,
      width: "150px",
    },
    {
      id: 2,
      name: "confirmed",
      logo: ConfirmedIcon,
      num: 3,
      text: `${t("confimBadge")}`,
      width: "150px",
    },
    {
      id: 3,
      name: "expert",
      logo: ExpertIcon,
      num: 4,
      text: `${t("expBadge")}`,
      width: "150px",
    },
    {
      id: 4,
      name: "attendance",
      logo: AttendanceIcon,
      num: 5,
      text: `${t("attenBadge")}`,
      width: "150px",
    },
    {
      id: 5,
      name: "bestLearn",
      logo: BestLearnIcon,
      num: 6,
      text: `${t("learBadge")}`,
      width: "300px !important",
    },
    {
      id: 6,
      name: "curious",
      logo: CuriousIcon,
      num: 7,
      text: `${t("auditBadge")}`,
      width: "190px",
    },
  ];

  return (
    <section id="clients" className="vc_section isMobile">
      <div className="slideshow-container bg-grey">
        <section id="testim" className="testim">
          <div className="wrap">
            <span
              onClick={nextSlide}
              id="right-arrow"
              className="arrow right fa fa-chevron-right"
            ></span>
            <span
              onClick={prevSlide}
              id="left-arrow"
              className="arrow left fa fa-chevron-left "
            ></span>
            <ul id="testim-dots" className="dots">
              {BadgesList.map((res) => (
                <li
                  onClick={() => setIdSlide(res.id)}
                  key={res.id}
                  className={idSlide === res.id ? "dot active" : "dot"}
                ></li>
              ))}
            </ul>
            <div className="cont">
              {BadgesList.map((res) => (
                <div
                  id="testim-content"
                  key={res.id}
                  onChange={() => setIdSlide(res.id)}
                  className={idSlide === res.id ? "active" : ""}
                >
                  <div className="img">
                    <img src={res.logo} alt="pict" />

                    <p>{res.text}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
      </div>
    </section>
  );
};

export default AnnoncesMobile;

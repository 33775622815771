import "./scrollIndicator-style.css";

const ScrollIndicatorComponent = ({ scrollIndicatorAppear }) => {
  window.onscroll = function () {
    let winScroll =
      document.body.scrollTop || document.documentElement.scrollTop;
    let height =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight;
    let scrolled = (winScroll / height) * 100;
    document.getElementById("myBar").style.width = scrolled + "%";
  };
  return (
    <div
      className={scrollIndicatorAppear ? "header-scroolIndicator" : "hidden"}
    >
      <div className="progress-container">
        <div className="progress-bar" id="myBar"></div>
      </div>
    </div>
  );
};

export default ScrollIndicatorComponent;

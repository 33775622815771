import "./screenshots-style.css";
import Carousel from "./carousel/Carousel";
import { useTranslation } from "react-i18next";
import UsCarousel from "./carousel/UsCarousel";

const Screenshots = () => {
  const { t, i18n } = useTranslation();
  return (
    <section
      data-inviewport="scaleUp"
      id="screenshots"
      className=" bg-grey vc_section-screenshots"
    >
      <div className="section-title text-center  ">
        <h3>{t("galleryTitle")}</h3>
        <p className="subtitleScreenshots">{t("gallerySub")}</p>
      </div>

      <div className="carousel-container">
        {i18n.language === "fr" ? <Carousel /> : <UsCarousel />}
      </div>
    </section>
  );
};

export default Screenshots;

import "./home-style.css";
import SingleImg from "../../assets/Meditating-at-sunset-opt.jpg";
import PhoneFrame from "../phoneFrame/PhoneFrame";
import qrcodeShareAtom from "../../stores/qrcodeShareAtom";
import { useRecoilState } from "recoil";
import { isMobile } from "react-device-detect";
import QrCodeShare from "../qrcodeShare/QrCodeShare";
import { useTranslation } from "react-i18next";

const Home = () => {
  const { t, i18n } = useTranslation();
  const [qrcodeShare, setQrcodeShare] = useRecoilState(qrcodeShareAtom);

  const handleQrcodeShare = () => {
    setQrcodeShare((qrcodeShare) => !qrcodeShare);
  };
  return (
    <section
      id="home"
      style={{ position: "relative", zIndex: 1, background: "none" }}
      className="banner image-bg "
    >
      {/*<img src={SingleImg} alt="banner" />*/}
      <div className="container firstview">
        <div className="wpb_wrapper firstviewleft">
          <div className="banner-text">
            <h1
              data-inviewport="fadeInUp"
              className="wow"
              style={{
                visibility: "visible",
              }}
            >
              {t("meditate")} <em>{t("meditate2")}</em>
            </h1>
            <p
              data-inviewport="fadeInUpMediumDelay"
              className="wow  subHome"
              style={{
                visibility: "visible",
              }}
            >
              {t("homeSub")}
            </p>
          </div>
          <div
            data-inviewport="fadeInUpLargeDelay"
            className="button-store wow"
            style={{
              visibility: "visible",
            }}
          >
            <a
              className="custom-btn  d-inline-flex align-items-center m-2 m-sm-0 mr-sm-3"
              href="#home"
            >
              <i className="fab fa-google-play"></i>
              <p>
                <em>{t("soonButton")}</em>Google Play
              </p>
            </a>
            {isMobile ? (
              i18n.language === "fr" ? (
                <a
                  className="custom-btn  d-inline-flex align-items-center m-2 m-sm-0 "
                  href="https://my-meditation-time.fr/"
                  target="new"
                >
                  <i className="fas fa-globe"></i>
                  <p>
                    <em>{t("installButton")}</em>
                    {t("webButton")}
                  </p>
                </a>
              ) : (
                <a
                  className="custom-btn  d-inline-flex align-items-center m-2 m-sm-0 "
                  href="https://my-meditation-time.us/"
                  target="new"
                >
                  <i className="fas fa-globe"></i>
                  <p>
                    <em>{t("installButton")}</em>
                    {t("webButton")}
                  </p>
                </a>
              )
            ) : (
              <button
                style={{ background: "transparent" }}
                className="custom-btn  d-inline-flex align-items-center m-2 m-sm-0 "
                onClick={handleQrcodeShare}
              >
                <i className="fas fa-globe"></i>
                <p>
                  <em>{t("installButton")}</em>
                  {t("qrcodeButton")}
                </p>
              </button>
            )}
          </div>
        </div>
        {qrcodeShare ? <QrCodeShare /> : null}
        <div
          data-inviewport="fadeInUp"
          className=" wow "
          style={{
            perspective: 1000,
            width: "42%",
            height: "37%",
            // transform: "scale(0.89) translate(0, 16rem)",
          }}
        >
          <div className="bounce-effect banner-image ">
            <div className=" phoneFrame-container ">
              <PhoneFrame />
            </div>
            <div className=" phoneFrame-container2 ">
              <PhoneFrame />
            </div>
          </div>

          {/*<img className="bounce-effect" src={Phones} alt="" />*/}
        </div>
      </div>

      <div className="wave-effect wave-anim">
        <div className="waves-shape shape-one">
          <div className="wave wave-one"></div>
        </div>

        <div className="waves-shape shape-two">
          <div className="wave wave-two"></div>
        </div>

        <div className="waves-shape shape-three">
          <div className="wave wave-three"></div>
        </div>
      </div>

      <div
        className="backstretch"
        style={{
          left: "0px",
          top: "0px",
          overflow: "hidden",
          margin: "0px",
          padding: "0px",
          height: "100%",
          width: "100%",
          zIndex: -999998,
          position: "absolute",
        }}
      >
        <img
          loading="lazy"
          src={SingleImg}
          alt="alt"
          style={{
            position: "absolute",
            margin: "0px",
            padding: "0px",
            border: "none",
            width: "100%",
            height: "100%",
            maxHeight: "none",
            maxWidth: "none",
            zIndex: -999999,
            left: 0,
            top: "0px",
            objectFit: "cover",
          }}
        />
      </div>
    </section>
  );
};

export default Home;

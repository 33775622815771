import "./sliderAnnonces-style.css";
import BeginnerIcon from "../../../assets/annonces/beginner.png";
import IntermedIcon from "../../../assets/annonces/intermediate.png";
import ConfirmedIcon from "../../../assets/annonces/confirmed.png";
import ExpertIcon from "../../../assets/annonces/expert.png";

import BestLearnIcon from "../../../assets/annonces/bestLearner-opt.png";
import CuriousIcon from "../../../assets/annonces/curiousBadge-opt.png";
import AttendanceIcon from "../../../assets/annonces/attendance-home.png";
import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const SliderAnnonces = () => {
  const { t } = useTranslation();
  const [clickedLevels, setClickedLevels] = useState(true);
  const [clickedBadges, setClickedBadges] = useState(false);

  const handleClickLevels = () => {
    setClickedLevels(true);
    setClickedBadges(false);
  };

  const handleClickedBadges = () => {
    setClickedBadges(true);
    setClickedLevels(false);
  };

  useEffect(() => {
    const levelsAnime = document.getElementById("ListLevel");
    const badgesAnime = document.getElementById("ListBadge");
    if (clickedLevels) {
      badgesAnime.classList.add("mi-moveToLeft");

      setTimeout(() => {
        badgesAnime.classList.remove("mi-moveFromRight");
        levelsAnime.classList.remove("mi-moveToRight");
        levelsAnime.classList.add("mi-moveFromLeft");
      }, 500);
    }
    if (clickedBadges) {
      badgesAnime.classList.remove("mi-moveToLeft");

      levelsAnime.classList.add("mi-moveToRight");
      setTimeout(() => {
        levelsAnime.classList.remove("mi-moveFromLeft");
        badgesAnime.classList.add("mi-moveFromRight");
      }, 500);
    }

    const autoSlideBadges = setInterval(() => {
      setTimeout(() => {
        setClickedLevels(true);
        setClickedBadges(false);
      }, 3700);
      setClickedLevels(false);
      setClickedBadges(true);
    }, 3700);

    return () => {
      clearTimeout(autoSlideBadges);
      clearInterval(autoSlideBadges);
    };
  }, [clickedLevels, clickedBadges]);

  const levelsArray = [
    {
      id: 3,
      logo: BeginnerIcon,
      text: `${t("beginner")}!`,
      width: "110px",
    },
    {
      id: 4,
      logo: IntermedIcon,
      text: `${t("intermed")}!`,
      width: "110px",
    },
    {
      id: 5,
      logo: ConfirmedIcon,
      text: `${t("confirmed")}!`,
      width: "110px",
    },
    {
      id: 6,
      logo: ExpertIcon,
      text: "Expert!",
      width: "110px",
    },
  ];

  const BadgesList = [
    {
      id: 0,
      logo: AttendanceIcon,
      text: `${t("attendance")}!`,
      width: "110px",
    },
    {
      id: 1,
      logo: BestLearnIcon,
      text: `${t("learn")}!`,
      width: "200px",
    },
    {
      id: 2,
      logo: CuriousIcon,
      text: `${t("auditory")}!`,
      width: "151px",
    },
  ];
  return (
    <Fragment>
      <div id="mi-slider" className="mi-slider">
        <ul id="ListLevel" className="levelList ">
          {levelsArray.map((res) => (
            <li key={res.id}>
              <img
                loading="lazy"
                src={res.logo}
                alt="img01"
                style={{ width: `${res.width}` }}
              />
              <h4 style={{ fontSize: 17 }}>{res.text}</h4>
            </li>
          ))}
        </ul>

        <ul id="ListBadge" className="levelList">
          {BadgesList.map((res) => (
            <li key={res.id}>
              <img
                loading="lazy"
                style={{ width: `${res.width}` }}
                src={res.logo}
                alt="img01"
              />
              <h4>{res.text}</h4>
            </li>
          ))}
        </ul>
        <nav>
          <span
            className={clickedLevels ? "mi-selected" : "clickLevel"}
            onClick={handleClickLevels}
          >
            {t("levelBadges")}
          </span>
          <span
            className={clickedBadges ? "mi-selected" : "clickLevel"}
            onClick={handleClickedBadges}
          >
            {t("otherBadges")}
          </span>
        </nav>
      </div>
    </Fragment>
  );
};

export default SliderAnnonces;

import React, { useEffect, useState } from "react";
import "./carousel-style.css";
import {
  Usligth1,
  Usligth2,
  Usligth3,
  Usligth4,
  Usligth5,
  Usligth6,
  Usligth7,
  Usligth8,
  Usligth9,
  Usligth10,
  Usligth11,
  Usligth12,
  Usligth13,
  Usdark1,
  Usdark2,
  Usdark3,
  Usdark4,
  Usdark5,
  Usdark6,
  Usdark7,
  Usdark8,
  Usdark9,
  Usdark10,
  Usdark11,
} from "../exportImages";

const slides = [
  {
    id: 0,
    title: "Accueil",
    subtitle: "far fa-lightbulb",
    description: "Simple et intuitive",
    image: Usligth1,
  },
  {
    id: 1,
    title: "Accueil",
    subtitle: "fas fa-moon",
    description: "Sobre & élégante",
    image: Usdark1,
  },
  {
    id: 2,
    title: "Pourquoi?",
    subtitle: "far fa-lightbulb",
    description: "Section bienfaits",
    image: Usligth2,
  },
  {
    id: 3,
    title: "Pourquoi?",
    subtitle: "fas fa-moon",
    description: "Section bienfaits",
    image: Usdark2,
  },
  {
    id: 4,
    title: "Infos détaillées",
    subtitle: "far fa-lightbulb",
    description: "Claires & préçises",
    image: Usligth3,
  },
  {
    id: 5,
    title: "Infos détaillées",
    subtitle: "fas fa-moon",
    description: "Claires & préçises",
    image: Usdark3,
  },
  {
    id: 6,
    title: "Comment?",
    subtitle: "far fa-lightbulb",
    description: "Explications & exercices",
    image: Usligth4,
  },
  {
    id: 7,
    title: "Comment?",
    subtitle: "fas fa-moon",
    description: "Explications & exercices",
    image: Usdark4,
  },
  {
    id: 8,
    title: "Conseils Préçis",
    subtitle: "far fa-lightbulb",
    description: "Chaque conseil à son explication",
    image: Usligth5,
  },
  {
    id: 9,
    title: "Conseils Préçis",
    subtitle: "fas fa-moon",
    description: "Chaque conseil à son explication",
    image: Usdark5,
  },
  {
    id: 10,
    title: "Les Pré-réglages",
    subtitle: "far fa-lightbulb",
    description: "Durées & sons disponibles",
    image: Usligth6,
  },
  {
    id: 11,
    title: "Les Pré-réglages",
    subtitle: "fas fa-moon",
    description: "Durées & sons disponible",
    image: Usdark6,
  },
  {
    id: 12,
    title: "Session rapide",
    subtitle: "far fa-lightbulb",
    description: "Choisissez une durée & un son, et voilà!",
    image: Usligth7,
  },
  {
    id: 13,
    title: "Session rapide",
    subtitle: "fas fa-moon",
    description: "Choisissez une durée & un son, et voilà!",
    image: Usdark7,
  },
  {
    id: 14,
    title: "Votre session",
    subtitle: "far fa-lightbulb",
    description: "Démarrer votre session ici.",
    image: Usligth8,
  },
  {
    id: 15,
    title: "Votre session",
    subtitle: "fas fa-moon",
    description: "Démarrer votre session ici.",
    image: Usdark8,
  },
  {
    id: 16,
    title: "L'historique",
    subtitle: "far fa-lightbulb",
    description: "Toutes vos session sont ici!",
    image: Usligth9,
  },
  {
    id: 17,
    title: "L'historique",
    subtitle: "fas fa-moon",
    description: "Toutes vos session sont ici!",
    image: Usdark9,
  },
  {
    id: 18,
    title: "Choix du logo",
    subtitle: "far fa-lightbulb",
    description: "30 logos sont disponibles.",
    image: Usligth10,
  },
  {
    id: 19,
    title: "Choix du logo",
    subtitle: "fas fa-moon",
    description: "30 logos sont disponibles.",
    image: Usdark10,
  },
  {
    id: 20,
    title: "Simplement...",
    subtitle: "far fa-lightbulb",
    description: "Choisissez & validez!",
    image: Usligth11,
  },
  {
    id: 21,
    title: "Simplement...",
    subtitle: "fas fa-moon",
    description: "Choisissez & validez!",
    image: Usdark11,
  },
  {
    id: 22,
    title: "Bouton section enfants",
    subtitle: "far fa-lightbulb",
    description: "Section pour les tout-petits.",
    image: Usligth12,
  },
  {
    id: 23,
    title: "Bouton section enfants",
    subtitle: "far fa-lightbulb",
    description: "Section pour les tout-petits.",
    image: Usligth13,
  },
];

function useTilt(active) {
  const ref = React.useRef(null);

  React.useEffect(() => {
    if (!ref.current || !active) {
      return;
    }

    const state = {
      rect: undefined,
      mouseX: undefined,
      mouseY: undefined,
    };

    let el = ref.current;

    const handleMouseMove = (e) => {
      if (!el) {
        return;
      }
      if (!state.rect) {
        state.rect = el.getBoundingClientRect();
      }
      state.mouseX = e.clientX;
      state.mouseY = e.clientY;
      const px = (state.mouseX - state.rect.left) / state.rect.width;
      const py = (state.mouseY - state.rect.top) / state.rect.height;

      el.style.setProperty("--px", px);
      el.style.setProperty("--py", py);
    };

    el.addEventListener("mousemove", handleMouseMove);

    return () => {
      el.removeEventListener("mousemove", handleMouseMove);
    };
  }, [active]);

  return ref;
}

const initialState = {
  slideIndex: 0,
};

const slidesReducer = (state, event) => {
  if (event.type === "NEXT") {
    return {
      ...state,
      slideIndex: (state.slideIndex + 1) % slides.length,
    };
  }
  if (event.type === "PREV") {
    return {
      ...state,
      slideIndex:
        state.slideIndex === 0 ? slides.length - 1 : state.slideIndex - 1,
    };
  }
};

function Slide({ slide, offset }) {
  const active = offset === 0 ? true : null;
  const ref = useTilt(active);

  return (
    <div
      ref={ref}
      className="slide"
      data-active={active}
      style={{
        "--offset": offset,
        "--dir": offset === 0 ? 0 : offset > 0 ? 1 : -1,
      }}
    >
      <div
        className="slideBackground"
        style={{
          backgroundImage: `url('${slide.image}')`,
        }}
      />
      <div
        className="slideContent"
        style={{
          backgroundImage: `url('${slide.image}')`,
        }}
      >
        <div className="slideContentInner">
          <h2 className="slideTitle">{slide.title}</h2>
          <h3 className="slideSubtitle">
            <i className={`${slide.subtitle}`}></i>
          </h3>
          <p className="slideDescription">{slide.description}</p>
        </div>
      </div>
    </div>
  );
}

function UsCarousel() {
  const [state, dispatch] = React.useReducer(slidesReducer, initialState);
  const [clickedAutomatic, setClickedAutomatic] = useState(false);

  useEffect(() => {
    if (clickedAutomatic) {
      const autoSlideBadges = setInterval(() => {
        dispatch({ type: "NEXT" });
      }, 5000);
      return () => {
        clearTimeout(autoSlideBadges);
        clearInterval(autoSlideBadges);
      };
    }
  }, [clickedAutomatic]);

  const handleClickAutomatic = () => {
    setClickedAutomatic((clickedAutomatic) => !clickedAutomatic);
  };

  return (
    <div className="slides">
      <button
        onClick={() => {
          setClickedAutomatic(false);
          dispatch({ type: "PREV" });
        }}
      >
        ‹
      </button>

      {[...slides, ...slides, ...slides].map((slide, i) => {
        let offset = slides.length + (state.slideIndex - i);
        return <Slide slide={slide} offset={offset} key={i} />;
      })}
      <span
        title="Mode Slide automatique"
        data-toggle="tooltip"
        data-placement="left"
        onClick={handleClickAutomatic}
        className={
          !clickedAutomatic
            ? "autoSlideButton"
            : "autoSlideButton whiteBackground RedColor"
        }
      >
        {clickedAutomatic ? (
          <div className="autoCount" />
        ) : (
          <i className="far fa-clock"></i>
        )}
      </span>
      <button
        onClick={() => {
          setClickedAutomatic(false);
          dispatch({ type: "NEXT" });
        }}
      >
        ›
      </button>
    </div>
  );
}

export default UsCarousel;

import { useState } from "react";
import { useTranslation } from "react-i18next";
import MailchimpSubscribe from "react-mailchimp-subscribe";
import "./subscribe-style.css";

const url =
  "https://amano-hilot.us1.list-manage.com/subscribe/post?u=0fefa2f358eded0c75fab6b26&amp;id=7f0b982664";

const CustomForm = ({ status, onValidated }) => {
  const { t } = useTranslation();
  let [email, setEmail] = useState("");
  const message =
    status === "success" ? `${t("thanxNews")}` : `${t("errorNews")}`;
  const submit = () => {
    email &&
      email?.value.indexOf("@") > -1 &&
      onValidated({
        EMAIL: email?.value,
      });
    setEmail("");
  };

  return (
    <div
      className="buttonSubContent"
      style={{
        // background: "#efefef",
        borderRadius: 2,
        padding: 10,
        display: "inline-block",
      }}
    >
      {status === "sending" && (
        <div style={{ color: "blue" }}>{t("sending")}</div>
      )}
      {status === "error" && (
        <div
          style={{ color: "red" }}
          dangerouslySetInnerHTML={{ __html: message }}
        />
      )}
      {status === "success" && (
        <div
          style={{ color: "green", marginBottom: 15 }}
          dangerouslySetInnerHTML={{ __html: message }}
        />
      )}
      <input
        className="form-control field-subscribe"
        style={{ fontSize: "1em", padding: 12 }}
        ref={(node) => (email = node)}
        type="email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        placeholder="Votre Email"
      />
      <br />
      <button
        style={{
          fontSize: "1em",
          padding: 15,
          backgroundColor: "#8537cc",
          marginTop: 20,
          borderRadius: 7,
          color: "white",
          width: "100%",
        }}
        onClick={submit}
      >
        {t("sendButton")}
      </button>
    </div>
  );
};

const Subscribe = () => {
  const { t } = useTranslation();
  return (
    <section
      id="subscribe"
      className="vc_section vc_custom_1622206318649 parallax subscribe"
    >
      <div className="overlay"></div>

      <div className="section-title subscribesection-title text-center  white">
        <h3>{t("newsletterTitle")}</h3>
        <p>{t("newsletterSub")}</p>
      </div>

      <div className="subscribe-content">
        <div id="subscribe-form">
          <MailchimpSubscribe
            url={url}
            render={({ subscribe, status, message }) => (
              <CustomForm
                status={status}
                message={message}
                onValidated={(formData) => subscribe(formData)}
              />
            )}
          />
        </div>
        <h3 id="subscribe-result" className="text-center text-white">
          {t("thanksMessage")}
        </h3>
        <div className="vc_empty_space" style={{ height: "30px" }}>
          <span className="vc_empty_space_inner"></span>
        </div>
        <div className="wpb_text_column wpb_content_element ">
          <div className="wpb_wrapper subscribe-bottom">
            <p className="mb-0" style={{ textAlign: "center" }}>
              {t("confidentiality")}{" "}
              <a href="https://amano-hilot.fr/" target="new">
                <strong>{t("confiden")}</strong>
              </a>{" "}
              {t("moreInfos")}
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Subscribe;

import React, { useEffect, useState } from "react";
import "./carousel-style.css";
import {
  ligth1,
  ligth2,
  ligth3,
  ligth4,
  ligth5,
  ligth6,
  ligth7,
  ligth8,
  ligth9,
  ligth10,
  ligth11,
  ligth12,
  ligth13,
  ligth14,
  ligth15,
  dark1,
  dark2,
  dark3,
  dark4,
  dark5,
  dark6,
  dark7,
  dark8,
  dark9,
  dark10,
  dark11,
  dark12,
  dark13,
  dark14,
  dark15,
} from "../exportImages";

const slides = [
  {
    id: 0,
    title: "Accueil",
    subtitle: "far fa-lightbulb",
    description: "Simple et intuitive",
    image: ligth1,
  },
  {
    id: 1,
    title: "Accueil",
    subtitle: "fas fa-moon",
    description: "Sobre & élégante",
    image: dark1,
  },
  {
    id: 2,
    title: "Pourquoi?",
    subtitle: "far fa-lightbulb",
    description: "Section bienfaits",
    image: ligth2,
  },
  {
    id: 3,
    title: "Pourquoi?",
    subtitle: "fas fa-moon",
    description: "Section bienfaits",
    image: dark2,
  },
  {
    id: 4,
    title: "Infos détaillées",
    subtitle: "far fa-lightbulb",
    description: "Claires & préçises",
    image: ligth3,
  },
  {
    id: 5,
    title: "Infos détaillées",
    subtitle: "fas fa-moon",
    description: "Claires & préçises",
    image: dark3,
  },
  {
    id: 6,
    title: "Comment?",
    subtitle: "far fa-lightbulb",
    description: "Explications & exercices",
    image: ligth4,
  },
  {
    id: 7,
    title: "Comment?",
    subtitle: "fas fa-moon",
    description: "Explications & exercices",
    image: dark4,
  },
  {
    id: 8,
    title: "Conseils Préçis",
    subtitle: "far fa-lightbulb",
    description: "Chaque conseil à son explication",
    image: ligth5,
  },
  {
    id: 9,
    title: "Conseils Préçis",
    subtitle: "fas fa-moon",
    description: "Chaque conseil à son explication",
    image: dark5,
  },
  {
    id: 10,
    title: "Les Pré-réglages",
    subtitle: "far fa-lightbulb",
    description: "Durées & sons disponibles",
    image: ligth6,
  },
  {
    id: 11,
    title: "Les Pré-réglages",
    subtitle: "fas fa-moon",
    description: "Durées & sons disponible",
    image: dark6,
  },
  {
    id: 12,
    title: "Session rapide",
    subtitle: "far fa-lightbulb",
    description: "Choisissez une durée & un son, et voilà!",
    image: ligth7,
  },
  {
    id: 13,
    title: "Session rapide",
    subtitle: "fas fa-moon",
    description: "Choisissez une durée & un son, et voilà!",
    image: dark7,
  },
  {
    id: 14,
    title: "Votre session",
    subtitle: "far fa-lightbulb",
    description: "Démarrer votre session ici.",
    image: ligth8,
  },
  {
    id: 15,
    title: "Votre session",
    subtitle: "fas fa-moon",
    description: "Démarrer votre session ici.",
    image: dark8,
  },
  {
    id: 16,
    title: "L'historique",
    subtitle: "far fa-lightbulb",
    description: "Toutes vos session sont ici!",
    image: ligth9,
  },
  {
    id: 17,
    title: "L'historique",
    subtitle: "fas fa-moon",
    description: "Toutes vos session sont ici!",
    image: dark9,
  },
  {
    id: 18,
    title: "Choix du logo",
    subtitle: "far fa-lightbulb",
    description: "12 logos sont disponibles.",
    image: ligth10,
  },
  {
    id: 19,
    title: "Choix du logo",
    subtitle: "fas fa-moon",
    description: "12 logos sont disponibles.",
    image: dark10,
  },
  {
    id: 20,
    title: "Simplement...",
    subtitle: "far fa-lightbulb",
    description: "Choisissez & validez!",
    image: ligth11,
  },
  {
    id: 21,
    title: "Simplement...",
    subtitle: "fas fa-moon",
    description: "Choisissez & validez!",
    image: dark11,
  },
  {
    id: 22,
    title: "Bouton section enfants",
    subtitle: "far fa-lightbulb",
    description: "Section pour les tout-petits.",
    image: ligth12,
  },
  {
    id: 23,
    title: "Bouton section enfants",
    subtitle: "fas fa-moon",
    description: "Section pour les tout-petits.",
    image: dark12,
  },
  {
    id: 24,
    title: "Bouton section enfants",
    subtitle: "far fa-lightbulb",
    description: "Section pour les tout-petits.",
    image: ligth13,
  },
  {
    id: 25,
    title: "Bouton section enfants",
    subtitle: "fas fa-moon",
    description: "Section pour les tout-petits.",
    image: dark13,
  },
  {
    id: 26,
    title: "Message de bienvenue",
    subtitle: "far fa-lightbulb",
    description: "transition sympa",
    image: ligth14,
  },
  {
    id: 27,
    title: "Message de bienvenue",
    subtitle: "fas fa-moon",
    description: "transition sympa",
    image: dark14,
  },
  {
    id: 28,
    title: "Accueil tout-petits",
    subtitle: "far fa-lightbulb",
    description: "méditation guidée.",
    image: ligth15,
  },
  {
    id: 29,
    title: "Accueil tout-petits",
    subtitle: "dark mode",
    description: "méditation guidée.",
    image: dark15,
  },
];

function useTilt(active) {
  const ref = React.useRef(null);

  React.useEffect(() => {
    if (!ref.current || !active) {
      return;
    }

    const state = {
      rect: undefined,
      mouseX: undefined,
      mouseY: undefined,
    };

    let el = ref.current;

    const handleMouseMove = (e) => {
      if (!el) {
        return;
      }
      if (!state.rect) {
        state.rect = el.getBoundingClientRect();
      }
      state.mouseX = e.clientX;
      state.mouseY = e.clientY;
      const px = (state.mouseX - state.rect.left) / state.rect.width;
      const py = (state.mouseY - state.rect.top) / state.rect.height;

      el.style.setProperty("--px", px);
      el.style.setProperty("--py", py);
    };

    el.addEventListener("mousemove", handleMouseMove);

    return () => {
      el.removeEventListener("mousemove", handleMouseMove);
    };
  }, [active]);

  return ref;
}

const initialState = {
  slideIndex: 0,
};

const slidesReducer = (state, event) => {
  if (event.type === "NEXT") {
    return {
      ...state,
      slideIndex: (state.slideIndex + 1) % slides.length,
    };
  }
  if (event.type === "PREV") {
    return {
      ...state,
      slideIndex:
        state.slideIndex === 0 ? slides.length - 1 : state.slideIndex - 1,
    };
  }
};

function Slide({ slide, offset }) {
  const active = offset === 0 ? true : null;
  const ref = useTilt(active);

  return (
    <div
      ref={ref}
      className="slide"
      data-active={active}
      style={{
        "--offset": offset,
        "--dir": offset === 0 ? 0 : offset > 0 ? 1 : -1,
      }}
    >
      <div
        className="slideBackground"
        style={{
          backgroundImage: `url('${slide.image}')`,
        }}
      />
      <div
        className="slideContent"
        style={{
          backgroundImage: `url('${slide.image}')`,
        }}
      >
        <div className="slideContentInner">
          <h2 className="slideTitle">{slide.title}</h2>
          <h3 className="slideSubtitle">
            <i className={`${slide.subtitle}`}></i>
          </h3>
          <p className="slideDescription">{slide.description}</p>
        </div>
      </div>
    </div>
  );
}

function Carousel() {
  const [state, dispatch] = React.useReducer(slidesReducer, initialState);
  const [clickedAutomatic, setClickedAutomatic] = useState(false);

  useEffect(() => {
    if (clickedAutomatic) {
      const autoSlideBadges = setInterval(() => {
        dispatch({ type: "NEXT" });
      }, 5000);
      return () => {
        clearTimeout(autoSlideBadges);
        clearInterval(autoSlideBadges);
      };
    }
  }, [clickedAutomatic]);

  const handleClickAutomatic = () => {
    setClickedAutomatic((clickedAutomatic) => !clickedAutomatic);
  };

  return (
    <div className="slides">
      <button
        onClick={() => {
          setClickedAutomatic(false);
          dispatch({ type: "PREV" });
        }}
      >
        ‹
      </button>

      {[...slides, ...slides, ...slides].map((slide, i) => {
        let offset = slides.length + (state.slideIndex - i);
        return <Slide slide={slide} offset={offset} key={i} />;
      })}
      <span
        title="Mode Slide automatique"
        data-toggle="tooltip"
        data-placement="left"
        onClick={handleClickAutomatic}
        className={
          !clickedAutomatic
            ? "autoSlideButton"
            : "autoSlideButton whiteBackground RedColor"
        }
      >
        {clickedAutomatic ? (
          <div className="autoCount" />
        ) : (
          <i className="far fa-clock"></i>
        )}
      </span>
      <button
        onClick={() => {
          setClickedAutomatic(false);
          dispatch({ type: "NEXT" });
        }}
      >
        ›
      </button>
    </div>
  );
}

export default Carousel;

import { useRecoilState } from "recoil";
import openMovieAtom from "../../stores/openMovieAtom";
import BlockSettings from "../block-settings/BlockSettings";
import Features from "../features/Features";
import Header from "../header/Header";
import Home from "../home/Home";
import Lightcase from "../lightcase/Lightcase";
import Overview from "../overview/Overview";
import ParallaxSection from "../parallaxSection/parallaxSection";
import Services from "../services/Services";
import ScrollToTop from "../scroll-top-button/ScrollTop";
import Testimonials from "../testimonials/Testimonials";
import Counters from "../counters/Counters";
import Team from "../team/Team";
import Screenshots from "../screenshots/Screenshots";
import Support from "../support/Support";
import Subscribe from "../subscribe/Subscribe";
import LatestPosts from "../latestPosts/LatestPosts";
import Pricing from "../pricing/Pricing";
import Contact from "../contact/Contact";
import Footer from "../footer/Footer";
import BaseLayout from "../../baseLayout/BaseLayout";
import AnnoncesWithSlider from "../annonces/AnnoncesWithSlider";
import { isMobile } from "react-device-detect";
import { useLayoutEffect } from "react";
import AnnoncesMobile from "../annonces/annoncesMobile/AnnoncesMobile";

const HomePage = () => {
  const [openMovie] = useRecoilState(openMovieAtom);

  useLayoutEffect(() => {
    const inViewport = (entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.toggle("is-inViewport", entry.isIntersecting);
        }
        return;
      });
    };
    function buildThresholdList() {
      let thresholds = [];
      let numSteps = 40;

      for (let i = 1.0; i <= numSteps; i++) {
        let ratio = i / numSteps;
        thresholds.push(ratio);
      }

      thresholds.push(0);
      return thresholds;
    }

    const Obs = new IntersectionObserver(inViewport);
    const obsOptions = {
      root: null,
      rootMargin: "0px",
      // thresholds: 0.5,
      threshold: buildThresholdList(),
    }; //See: https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API#Intersection_observer_options

    // Attach observer to every [data-inviewport] element:
    const ELs_inViewport = document.querySelectorAll("[data-inviewport]");
    ELs_inViewport.forEach((EL) => {
      Obs.observe(EL, obsOptions);
    });
    // FONCTION TO KNOWING IF ANY DIV IS MORE THAN 100% WIDTH
    // var docWidth = document.documentElement.offsetWidth;
    // [].forEach.call(document.querySelectorAll("*"), function (el) {
    //   if (el.offsetWidth > docWidth) {
    //     console.log(el);
    //   }
    // });
  }, []);

  return (
    <BaseLayout>
      <div className="homePage">
        <BlockSettings />
        <Header />
        <Home />
        {!isMobile ? <AnnoncesWithSlider /> : <AnnoncesMobile />}
        <Features />
        <ParallaxSection />
        <Services />
        {openMovie ? <Lightcase /> : null}
        <Overview />
        <Testimonials />
        <Counters />
        <Team />
        <Screenshots />
        <Support />
        <Subscribe />
        <LatestPosts />
        <Pricing />
        <Contact />
        <ScrollToTop />
        <Footer />
      </div>
    </BaseLayout>
  );
};

export default HomePage;

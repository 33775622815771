import { useTranslation } from "react-i18next";
import PhoneFrame from "../phoneFrame/PhoneFrame";
import "./pricing-style.css";
import Pricing3D from "./pricing3D/Pricing3D";

const Pricing = () => {
  const { t } = useTranslation();
  return (
    <section id="pricing" className="vc_section bg-grey">
      <div className="container container-pricing">
        <div className="section-title text-center  ">
          <h3>{t("tarifTitle")}</h3>
          <p>{t("tarifSub")}</p>
        </div>

        <div className="pricing3d">
          <Pricing3D />
        </div>

        <div className="priceAnimateContainer">
          <div
            data-inviewport="fadeInUp"
            className="wpb_single_image mobile-phone"
          >
            <figure className="wpb_wrapper vc_figure pricingBg">
              <div className=" phoneFrame-pricing-bottom">
                <PhoneFrame />
              </div>
            </figure>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Pricing;

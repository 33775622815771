import "./header-style.css";
import LogoWhite from "../../assets/logo-app2.png";
import Logo from "../../assets/logo-app2.png";
import { useEffect, useState } from "react";
import ScrollIndicatorComponent from "../scrollIndicator/ScrollIndicatorComponent";
import { useRecoilState } from "recoil";
import navigationNameAtom from "../../stores/navigationNameAtom";
import { useTranslation } from "react-i18next";

const Header = () => {
  const { t } = useTranslation();
  const [scrollIndicatorAppear, setscrollIndicatorAppear] = useState(false);
  const [navigationName, setNavigationName] =
    useRecoilState(navigationNameAtom);

  window.addEventListener("scroll", (e) => {
    var el = document.getElementById("mainNav");
    let logoBanner = document.getElementById("navbar-brand1");
    let LogoScroll = document.getElementById("navbar-brand2");
    if (window.scrollY > 100) {
      el.classList.add("slideInDown");
      el.classList.add("fixed-menu");
      LogoScroll.classList.remove("hidden");
      setscrollIndicatorAppear(true);
      logoBanner.classList.add("hidden");
    } else if (window.scrollY < 100) {
      el.classList.remove("slideInDown");
      el.classList.remove("fixed-menu");
      LogoScroll.classList.add("hidden");
      logoBanner.classList.remove("hidden");
      setscrollIndicatorAppear(false);
    }
  });

  const navigationNameArray = [
    {
      id: 0,
      name: `${t("homeMenu")}`,
      session: "home",
      link: "#home",
    },
    {
      id: 1,
      name: `${t("homeMenu2")}`,
      session: "features",
      link: "#features",
    },
    {
      id: 2,
      name: `${t("homeMenu3")}`,
      session: "screenshots",
      link: "#screenshots",
    },
    {
      id: 3,
      name: "support",
      session: "support",
      link: "#support",
    },
    {
      id: 4,
      name: `${t("homeMenu5")}`,
      session: "pricing",
      link: "#pricing",
    },
    {
      id: 5,
      name: "Publications",
      session: "blog",
      link: "#blog",
    },
    {
      id: 6,
      name: "contact",
      session: "contact",
      link: "#contact",
    },
  ];

  const [clickedMenu, setClickedMenu] = useState(false);

  const handleClickMobileMenu = () => {
    setClickedMenu((clickedMenu) => !clickedMenu);
  };

  useEffect(() => {
    let mobileMenu = document.getElementById("top-page");
    if (clickedMenu) {
      mobileMenu.classList.add("mobile-menu-open");
    } else {
      mobileMenu.classList.remove("mobile-menu-open");
    }
    if (navigationName === "") {
      setNavigationName("Accueil");
    }
    // console.log("namvigation name", navigationName);
  }, [clickedMenu, navigationName, setNavigationName]);

  const handleClickLink = (res) => {
    setNavigationName(res.name);
    setTimeout(() => {
      setClickedMenu(false);
    }, 50);
    return clearTimeout(setTimeout);
  };

  return (
    <header id="top-page" className="header">
      {/*slideInDown fixed-menu*/}
      <div id="mainNav" className="main-menu-area animated  animate ">
        <div className="container">
          <div className="row align-items-center">
            <ScrollIndicatorComponent
              scrollIndicatorAppear={scrollIndicatorAppear}
            />

            <div className="col-12 col-lg-2 d-flex justify-content-between align-items-center">
              <div className="logo">
                <a
                  id="navbar-brand1"
                  className="navbar-brand navbar-brand1"
                  href="/"
                >
                  <div className="logo-content">
                    <img
                      loading="lazy"
                      style={{ width: 45 }}
                      src={LogoWhite}
                      alt="My Meditation Time"
                      data-rjs="2"
                    />
                    <h1
                      className="textLogo"
                      style={{
                        color: "white",
                        fontSize: "1.4rem",
                        marginLeft: "12px",
                      }}
                    >
                      My Meditation Time
                    </h1>
                  </div>
                </a>

                <a
                  id="navbar-brand2"
                  className="navbar-brand navbar-brand2 hidden"
                  href="/"
                >
                  <img
                    loading="lazy"
                    style={{ width: 45 }}
                    src={Logo}
                    alt="My Meditation Time"
                    data-rjs="2"
                  />
                  <h1
                    className="textLogo"
                    style={{
                      color: "black",
                      fontSize: "1.4rem",
                      marginLeft: "12px",
                    }}
                  >
                    My Meditation Time
                  </h1>
                </a>
              </div>

              <div
                onClick={handleClickMobileMenu}
                className="menu-bar d-lg-none"
              >
                <span></span>
                <span></span>
                <span></span>
              </div>

              <div className="op-mobile-menu col-lg-10 p-0 d-lg-flex align-items-center justify-content-end">
                <div className="m-menu-header d-flex justify-content-between align-items-center d-lg-none">
                  <a href="/" className="logoMobile">
                    <img
                      loading="lazy"
                      style={{ width: 45 }}
                      src={Logo}
                      alt="My Meditation Time"
                      data-rjs="2"
                    />
                    <h1
                      className="textLogo"
                      style={{
                        color: "black",
                        fontSize: "1.4rem",
                        marginLeft: "12px",
                      }}
                    >
                      My Meditation Time
                    </h1>
                  </a>

                  <span
                    onClick={handleClickMobileMenu}
                    className="close-button"
                  ></span>
                </div>
                <div
                  className="fb-like"
                  data-href="https://www.facebook.com/meditationwithamanohilot"
                  data-width=""
                  data-layout="button"
                  data-action="like"
                  data-size="small"
                  data-share="false"
                ></div>
                <nav className="navigation">
                  <ul
                    id="menu-main-menu"
                    className="nav-menu d-lg-flex flex-wrap list-unstyled justify-content-center nav-search-icon"
                  >
                    {navigationNameArray.map((res) => (
                      <li
                        key={res.id}
                        id="menu-item"
                        className="menu-item menu-item-type-post_type menu-item-object-page menu-item-home current-menu-item page_item page-item-61 current_page_item menu-item-67 nav-item"
                      >
                        <a
                          onClick={() => handleClickLink(res)}
                          href={`${res.link}`}
                          aria-current="page"
                          className={
                            navigationName === res.name
                              ? "nav-link js-scroll-trigger active"
                              : "nav-link js-scroll-trigger"
                          }
                        >
                          <span>{res.name}</span>
                        </a>
                      </li>
                    ))}
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;

import { Fragment, useEffect, useState } from "react";
import "./realAppFrame-style.css";

import SplashScreenPhone from "./SplashScreenPhone";

import PoweredBy from "../../assets/startPhoneScreen/powered-by-android.png";
import AndroidLogo from "./AndroidLogo";
import { useTranslation } from "react-i18next";

const RealAppFrame = () => {
  const { i18n } = useTranslation();
  const [clickStartPhone, setClickStartPhone] = useState(false);
  const [startedPhone, setStartedPhone] = useState(false);

  const handleClickStartPhone = () => {
    if (!clickStartPhone) {
      setClickStartPhone(true);
    }
    if (clickStartPhone) {
      setClickStartPhone(false);
      setStartedPhone(false);
    }
  };

  useEffect(() => {
    if (clickStartPhone) {
      setTimeout(() => {
        setStartedPhone(true);
      }, 3500);
    }

    console.log("startedPhone", startedPhone);
  }, [clickStartPhone, startedPhone]);
  return (
    <div className="realFrame-container">
      <div id="phone">
        <div className="headContent">
          <span className="headPhone"></span>
          <span className="camPhone"></span>
        </div>

        <div id="screen">
          {clickStartPhone ? (
            <Fragment>
              <AndroidLogo />
              <SplashScreenPhone />
              <img
                src={PoweredBy}
                className="puff-in-center"
                alt="powered"
                style={{
                  width: "59%",
                  position: "absolute",
                  bottom: 55,
                  left: 52,
                  right: "auto",
                }}
              />
            </Fragment>
          ) : null}
          {startedPhone ? (
            <iframe
              id="inlineFrameExample"
              title="My Meditation Time"
              frameBorder="0"
              width="338"
              height="651"
              src={
                i18n.language === "fr"
                  ? "https://my-meditation-time.fr"
                  : "https://my-meditation-time.us"
              }
            ></iframe>
          ) : null}
        </div>

        <div className="icons-phone">
          <div className="icon-back"></div>
          <button onClick={handleClickStartPhone} className="phoneButton">
            <div className="icon-home"></div>
          </button>
          <div className="icon-recent"></div>
        </div>
      </div>
    </div>
  );
};

export default RealAppFrame;

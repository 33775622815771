import "./features-style.css";
import PhoneFrame from "../phoneFrame/PhoneFrame";
import DesktopLogos from "../desktopLogos/DesktopLogos";
import RealAppFrame from "../realAppFrame/RealAppFrame";
import arrowDraw from "../../assets/arrow.png";
import { useTranslation } from "react-i18next";
import AnimeFeatures from "./animeFeatures/AnimeFeatures";

const Features = () => {
  const { t } = useTranslation();

  return (
    <section id="features" className="vc_section ">
      <div className="container-features">
        <div className="section-title text-center  ">
          <div className="headCaracteristic">
            <h3>{t("mainFeatures")}</h3>
            <DesktopLogos />
          </div>
          <p className="subtitleFeatures">{t("featuresTitle")}</p>
        </div>
        <div data-inviewport="scaleUp" className="features-presentation">
          <div className="wpb_wrapper">
            <ul className="features-item">
              <li>
                <div className="feature-box  media">
                  <div className="box-icon">
                    <div className="icon icon-basic-gear">
                      <i className="fas fa-mobile-alt"></i>
                    </div>
                  </div>
                  <div className="box-text media-body align-self-center align-self-md-start">
                    <h4>{t("easyTextTitle")}</h4>
                    <p>{t("easyText")}</p>
                  </div>
                </div>
              </li>
              <li>
                <div className="feature-box  media">
                  <div className="box-icon">
                    <div className="icon icon-basic-lock">
                      <i className="fab fa-earlybirds"></i>
                    </div>
                  </div>
                  <div className="box-text media-body align-self-center align-self-md-start">
                    <h4>{t("funTextTitle")}</h4>
                    <p>{t("funText")}</p>
                  </div>
                </div>
              </li>
              <li>
                <div className="feature-box  media">
                  <div className="box-icon">
                    <div className="icon icon-basic-message-txt">
                      <i className="fas fa-plane"></i>
                    </div>
                  </div>
                  <div className="box-text media-body align-self-center align-self-md-start">
                    <h4>{t("offlineTextTitle")}</h4>
                    <p>{t("offlineText")}</p>
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <div className="pictureContnt">
            <div className="testIt-content">
              <p className="arrowText">{t("tryApp")}</p>
              <img className="arrowDraw" src={arrowDraw} alt="arrow" />
            </div>
            <figure className="figure">
              <div className="features-phone1" style={{ perspective: 1000 }}>
                <PhoneFrame />
              </div>
              <div className="features-phone">
                <RealAppFrame />
              </div>
            </figure>
            <div className="testIt-content content-back">
              <p className="arrowText">{t("startPhone")}</p>
              <img className="arrowDraw back" src={arrowDraw} alt="arrow" />
            </div>
          </div>
          <div className="wpb_wrapper">
            <ul className="features-item">
              <li>
                <div className="feature-box  media">
                  <div className="box-icon">
                    <div className="icon icon-basic-share">
                      <i className="fas fa-tools"></i>
                    </div>
                  </div>
                  <div className="box-text media-body align-self-center align-self-md-start">
                    <h4>{t("presetTextTitle")}</h4>
                    <p>{t("presetText")}</p>
                  </div>
                </div>
              </li>
              <li>
                <div className="feature-box  media">
                  <div className="box-icon">
                    <div className="icon icon-basic-sheet-multiple">
                      <i className="fas fa-stopwatch"></i>
                    </div>
                  </div>
                  <div className="box-text media-body align-self-center align-self-md-start">
                    <h4>{t("sessionTextTitle")}</h4>
                    <p>{t("sessionText")}</p>
                  </div>
                </div>
              </li>
              <li>
                <div className="feature-box  media">
                  <div className="box-icon">
                    <div className="icon icon-basic-alarm">
                      <i className="fas fa-history"></i>
                    </div>
                  </div>
                  <div className="box-text media-body align-self-center align-self-md-start">
                    <h4>{t("historyTextTitle")}</h4>
                    <p>{t("historyText")}</p>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>

        <div data-inviewport="scaleUp" className="features-presentation">
          <div className="wpb_wrapperpres">
            <h6
              style={{
                fontFamily: "Permanent Marker",
                fontStyle: "normal",
                fontWeight: "400",
                fontSize: 26,
                marginTop: "1rem",
              }}
            >
              {t("profileTextTitle")}
            </h6>
            <hr style={{ width: "100%", height: "2px" }} />

            <AnimeFeatures />
          </div>
          <div className="pictureContnt">
            <figure className="figure">
              <div
                className="features-phone1 features2a"
                style={{ perspective: 1000 }}
              >
                <PhoneFrame />
              </div>
              <div id="features2b" className="features-phone features2b">
                <PhoneFrame />
              </div>
            </figure>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Features;

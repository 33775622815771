/* eslint-disable jsx-a11y/anchor-is-valid */
import { useRecoilState } from "recoil";

import "./qrcodeShare.css";
import QrLink from "../../assets/qrcode_my-meditation-time.fr.png";
import USQrLink from "../../assets/qrcode_my-meditation-time.us.png";
import qrcodeShareAtom from "../../stores/qrcodeShareAtom";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";

const QrCodeShare = () => {
  const { t, i18n } = useTranslation();
  // eslint-disable-next-line no-unused-vars
  const [qrcodeShare, setQrcodeShare] = useRecoilState(qrcodeShareAtom);

  useEffect(() => {
    console.log(i18n.language);
  }, [i18n]);
  return (
    <div className="QrCodemodal-overlay">
      <div className="QrCodemodal">
        <a
          className="close-QrCodemodal"
          href="#"
          onClick={() => setQrcodeShare(false)}
        >
          <svg viewBox="0 0 20 20">
            <path
              fill="#000000"
              d="M15.898,4.045c-0.271-0.272-0.713-0.272-0.986,0l-4.71,4.711L5.493,4.045c-0.272-0.272-0.714-0.272-0.986,0s-0.272,0.714,0,0.986l4.709,4.711l-4.71,4.711c-0.272,0.271-0.272,0.713,0,0.986c0.136,0.136,0.314,0.203,0.492,0.203c0.179,0,0.357-0.067,0.493-0.203l4.711-4.711l4.71,4.711c0.137,0.136,0.314,0.203,0.494,0.203c0.178,0,0.355-0.067,0.492-0.203c0.273-0.273,0.273-0.715,0-0.986l-4.711-4.711l4.711-4.711C16.172,4.759,16.172,4.317,15.898,4.045z"
            ></path>
          </svg>
        </a>

        <div className="QrCodemodal-content">
          <div className="qrlink">
            <sub>{t("qr1")}</sub>
            <img
              src={i18n.language === "en" ? USQrLink : QrLink}
              alt="qrcode"
            />
            <sub>
              {t("qr2")}{" "}
              <a
                href={
                  i18n.language === "en"
                    ? "https://rodolphe37.github.io/my-meditation-time-userguide/README_US.html"
                    : "https://rodolphe37.github.io/my-meditation-time-userguide/"
                }
                target="new"
              >
                {t("qr3")}
              </a>{" "}
              {t("qr4")}
            </sub>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QrCodeShare;

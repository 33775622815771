import "./phoneFrame-style.css";

const PhoneFrame = ({ displaySounds }) => {
  return (
    <div id="phone">
      <div className="headContent">
        <span className="headPhone"></span>
        <span className="camPhone"></span>
      </div>

      <div
        id="screen"
        className={
          displaySounds === 1
            ? "defaultSound"
            : displaySounds === 2
            ? "intermediateSound"
            : "otherSounds"
        }
      ></div>

      <div className="top-bar"></div>
      <div className="headerPhone">
        <div className="lock"></div>
        <span className="timePhone">10:12:22</span>
        <div className="battery">
          <span>80%</span>
        </div>
        <div className="signal"></div>
        <div className="search-bar">
          <span>Search anything</span>
        </div>
        <div className="magnifying-glass"></div>
        <div className="time-and-date">
          <p>
            11:30 <span>Mon. 9, 2017</span>
          </p>
        </div>
      </div>
      <div className="weather-widget">
        <div className="cloud"></div>
        <div className="moon"></div>
        <span>23&#176;C</span>
      </div>
      <div className="icons">
        <li>
          <span className="instagram"></span>
          <p>Instagram</p>
        </li>
        <li>
          <span className="gallery"></span>
          <span className="circle"></span>
          <p>Gallery</p>
        </li>
        <li>
          <span className="messaging"></span>
          <p>Messaging</p>
        </li>
        <li>
          <span className="music"></span>
          <p>Music</p>
        </li>
        <li>
          <span className="contacts"></span>
          <p>Contacts</p>
        </li>
        <li>
          <span className="clock"></span>
          <span className="clock-hands"></span>
          <p>Clock</p>
        </li>
        <li>
          <span className="phone"></span>
          <p>Phone</p>
        </li>
        <li>
          <span className="voice"></span>
          <p>Voice</p>
        </li>
      </div>
      <div className="icons-bottom">
        <li>
          <span className="camera"></span> <span className="camera-lens"></span>
        </li>
        <li>
          <span className="youtube"></span>
        </li>
        <li>
          <span className="appdrawer"></span>
          <span className="appdrawer-up"></span>
          <span className="appdrawer-down"></span>
        </li>
        <li>
          <span className="mail"></span>
        </li>
        <li>
          <span className="file"></span>
          <span className="file-button"></span>
        </li>
      </div>
      <div className="icons-phone">
        <div className="icon-back"></div>
        <div className="icon-home"></div>
        <div className="icon-recent"></div>
      </div>
    </div>
  );
};

export default PhoneFrame;

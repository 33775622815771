import { useRecoilState } from "recoil";
import "./parallaxSection-style.css";
import openMovieAtom from "../../stores/openMovieAtom";
import { useTranslation } from "react-i18next";

const ParallaxSection = () => {
  const { t } = useTranslation();
  // eslint-disable-next-line no-unused-vars
  const [openMovie, setOpenMovie] = useRecoilState(openMovieAtom);

  return (
    <section
      id="parallax-video"
      className="vc_section vc_custom_1622204834130 parallax"
    >
      <div className="overlay"></div>

      <div className="row parallaxContent">
        <div className="video-btn wow " data-inviewport="fadeInUp">
          <button onClick={() => setOpenMovie(true)} className="play-btn">
            <i className="fas fa-play"></i>
          </button>
          <p className="video-text">{t("seeMovieButton")}</p>
        </div>
      </div>
      <span data-inviewport="fadeInUp"></span>
    </section>
  );
};

export default ParallaxSection;

import SliderAnnonces from "./sliderAnnonces/SliderAnnonces";

const AnnoncesWithSlider = () => {
  return (
    <section id="clients" className=" bg-grey">
      <SliderAnnonces />
    </section>
  );
};

export default AnnoncesWithSlider;

import { useRecoilState } from "recoil";
import openMovieAtom from "../../stores/openMovieAtom";
import "./lightcase-style.css";
import movieDemo from "../../assets/demo.webm";
import movieDemoMp4 from "../../assets/demo.mp4";

const Lightcase = () => {
  // eslint-disable-next-line no-unused-vars
  const [openMovie, setOpenMovie] = useRecoilState(openMovieAtom);
  return (
    <div>
      <div
        id="lightcase-overlay"
        style={{ opacity: 0.9, display: "block", cursor: "pointer" }}
      ></div>
      <div
        id="lightcase-loading"
        className="lightcase-icon-spin"
        style={{ display: "none" }}
      ></div>
      <div
        id="lightcase-case"
        aria-hidden="false"
        role="dialog"
        style={{
          opacity: 1,
          display: "block",
          maxWidth: "100%",
          width: 0,
          marginTop: "-250px",
          marginLeft: "-10%",
        }}
        className=""
      >
        <div id="lightcase-content">
          <div
            className="lightcase-contentInner"
            style={{
              opacity: 1,
              width: "auto",
              height: "auto",
              maxWidth: "100%",
            }}
          >
            <video controls width="350">
              <source src={movieDemo} type="video/webm" />

              <source src={movieDemoMp4} type="video/mp4" />

              <p>Sorry, your browser doesn't support embedded videos.</p>
            </video>
          </div>
        </div>
        <div id="lightcase-info" style={{ opacity: 1 }}>
          <div id="lightcase-sequenceInfo" style={{ display: "none" }}></div>
          <h4 id="lightcase-title" style={{ display: "none" }}>
            .
          </h4>
          <p id="lightcase-caption" style={{ display: "none" }}>
            .
          </p>
        </div>
      </div>
      <button
        onClick={() => setOpenMovie(false)}
        className="lightcase-icon-close"
        style={{ opacity: 0.7, zIndex: 2500 }}
      >
        <span className="lightcase-buttonContent">
          <p>X</p>
        </span>
      </button>
    </div>
  );
};

export default Lightcase;

const DesktopLogos = () => {
  return (
    <div className="desktopLogos">
      <i
        title="Ordinateurs Windows"
        data-toggle="tooltip"
        data-placement="left"
        className="iconDesktop fab fa-windows"
      ></i>
      <div
        title="Ordinateurs Mac"
        data-toggle="tooltip"
        data-placement="left"
        className="appleProd"
      >
        <i className="iconDesktop fas fa-desktop"></i>
        <i className="iconDesktop fab fa-apple"></i>
      </div>
      <i
        title="Chrome OS"
        data-toggle="tooltip"
        data-placement="left"
        className="iconDesktop fab fa-chrome"
      ></i>
      <i
        title="Ordinateur Linux"
        data-toggle="tooltip"
        data-placement="left"
        className="iconDesktop fab fa-linux"
      ></i>
      <i
        title="Téléphones Android"
        data-toggle="tooltip"
        data-placement="left"
        className="iconDesktop fab fa-android"
      ></i>
    </div>
  );
};

export default DesktopLogos;

import "./team-style.css";
import { useTranslation } from "react-i18next";
import TeamAnimated from "./teamAnimated/TeamAnimated";

const Team = () => {
  const { t } = useTranslation();
  return (
    <section data-inviewport="fadeInUp" id="team" className="vc_section">
      <div className="vc_row wpb_row vc_row-fluid">
        <div className="wpb_column vc_column_container vc_col-sm-12">
          <div className="vc_column-inner">
            <div className="wpb_wrapper">
              <div className="row justify-content-center">
                <div className="col-12 col-md-10 col-lg-6 titleTextContent">
                  <div className="section-title text-center  ">
                    <h3>{t("devTitle")}</h3>
                    <p>{t("devSub")}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="vc_row wpb_row vc_row-fluid teamPictures">
        <div className="wpb_column vc_column_container vc_col-sm-3">
          <div className="vc_column-inner">
            <div className="wpb_wrapper">
              <div className="team-member res-margin">
                <TeamAnimated />

                <div className="team-details">
                  <p>{t("devText")}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Team;

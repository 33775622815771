import "./testimonials-style.css";
import user1Pict from "../../assets/client-7.jpg";
import user2Pict from "../../assets/client-3.jpg";
import user3Pict from "../../assets/client-8.jpg";
import user4Pict from "../../assets/member-1.png";
import user5Pict from "../../assets/member-3.png";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const Testimonials = () => {
  const { t } = useTranslation();
  const [idSlide, setIdSlide] = useState(0);

  useEffect(() => {
    if (idSlide > 4) {
      setIdSlide(0);
    }
    if (idSlide === -1) {
      setIdSlide(4);
    }

    // console.log("Id Slide:", idSlide);
  }, [idSlide]);

  const nextSlide = () => {
    setIdSlide((idSlide) => idSlide + 1);
  };
  const prevSlide = () => {
    setIdSlide((idSlide) => idSlide - 1);
  };

  useEffect(() => {
    const autoSlide = setInterval(() => {
      nextSlide();
      if (idSlide > 4) {
        setIdSlide(0);
      }
      if (idSlide === -1) {
        setIdSlide(4);
      }
    }, 4500);
    return () => {
      clearInterval(autoSlide);
    };
  }, [idSlide]);

  const UserComments = [
    {
      id: 0,
      name: "Virginie",
      region: "paris",
      comment: `${t("userComments1")}`,
      img: user1Pict,
    },
    {
      id: 1,
      name: "Marc",
      region: "Bordeaux",
      comment: `${t("userComments2")}`,
      img: user2Pict,
    },
    {
      id: 2,
      name: "Samir",
      region: "Marseille",
      comment: `${t("userComments3")}!`,
      img: user3Pict,
    },
    {
      id: 3,
      name: "Marissa",
      region: "Tours",
      comment: `${t("userComments4")}!`,
      img: user4Pict,
    },
    {
      id: 4,
      name: "Marie",
      region: "Lille",
      comment: `${t("userComments5")}`,
      img: user5Pict,
    },
  ];

  return (
    <section id="testimonials" className="vc_section">
      <div className="container">
        <div className="wpb_wrapper-testi">
          <div className="section-title text-center  ">
            <h3>{t("userComments")}</h3>
            <p className="subtitleTesti">{t("userCommentsSub")} !</p>
          </div>
        </div>
        <section id="testim" className="testim">
          <div className="wrap">
            <span
              onClick={nextSlide}
              id="right-arrow"
              className="arrow right fa fa-chevron-right"
            ></span>
            <span
              onClick={prevSlide}
              id="left-arrow"
              className="arrow left fa fa-chevron-left "
            ></span>
            <ul id="testim-dots" className="dots">
              {UserComments.map((res) => (
                <li
                  onClick={() => setIdSlide(res.id)}
                  key={res.id}
                  className={idSlide === res.id ? "dot active" : "dot"}
                ></li>
              ))}
            </ul>
            <div className="cont">
              {UserComments.map((res) => (
                <div
                  id="testim-content"
                  key={res.id}
                  onChange={() => setIdSlide(res.id)}
                  className={idSlide === res.id ? "active" : ""}
                >
                  <div className="img">
                    <h2>{res.name}</h2>
                    <p>{res.region}</p>
                    <img src={res.img} alt="pict" />

                    <p>{res.comment}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
      </div>
    </section>
  );
};

export default Testimonials;

import "./footer-style.css";
import logoApp from "../../assets/logo-app2.png";
import { useTranslation } from "react-i18next";
import { isMobile } from "react-device-detect";
import qrcodeShareAtom from "../../stores/qrcodeShareAtom";
import { useRecoilState } from "recoil";
import SocialMedia from "./socialMedia/SocialMedia";

const Footer = () => {
  const { t } = useTranslation();
  // eslint-disable-next-line no-unused-vars
  const [qrcodeShare, setQrcodeShare] = useRecoilState(qrcodeShareAtom);

  const handleQrcodeShare = () => {
    setQrcodeShare((qrcodeShare) => !qrcodeShare);
  };
  return (
    <footer>
      <div className="footer-widgets">
        <div className="widget widget_social">
          <span className="logoFooter">
            <img
              loading="lazy"
              className="footer-logo text-center text-lg-left"
              src={logoApp}
              alt="apps"
              data-rjs="2"
            />
            <h1
              className="textLogo"
              style={{ color: "white", fontSize: "1.4rem", marginLeft: "12px" }}
            >
              My Meditation Time
            </h1>
          </span>

          <p className="textFooter1">{t("fotterDesc")}</p>

          <div className="author-social">
            <SocialMedia />
            {/*<div className="social">
              <a href="#">
                <i className="fab fa-facebook-f"></i>
              </a>
              <a href="#">
                <i className="fab fa-instagram"></i>
              </a>
              <a href="#">
                <i className="fab fa-linkedin"></i>
              </a>
              <a href="#">
                <i className="fab fa-pinterest"></i>
              </a>{" "}
            </div>*/}
          </div>
        </div>

        <div className="widget widget_nav_menu">
          <h6>{t("useLinks")}</h6>
          <div className="menu-useful-links-container">
            <ul id="menu-useful-links" className="menu">
              <li
                id="menu-item-17"
                className="menu-item menu-item-type-custom menu-item-object-custom menu-item-17"
              >
                <a href="https://rodolphe-augusto.fr" target="new">
                  Support
                </a>
              </li>
              <li
                id="menu-item-18"
                className="menu-item menu-item-type-custom menu-item-object-custom menu-item-18"
              >
                <a href="https://amano-hilot.fr" target="new">
                  {t("confidence")}
                </a>
              </li>
              <li
                id="menu-item-19"
                className="menu-item menu-item-type-custom menu-item-object-custom menu-item-19"
              >
                <a href="https://amano-hilot.fr" target="new">
                  {t("terms")}
                </a>
              </li>
              <li
                id="menu-item-20"
                className="menu-item menu-item-type-custom menu-item-object-custom menu-item-20"
              >
                <a href="#subscribe">{t("footersubscibe")}</a>
              </li>
              <li
                id="menu-item-16"
                className="menu-item menu-item-type-custom menu-item-object-custom menu-item-16"
              >
                <a href="https://my-meditation-time.us" target="new">
                  Version US
                </a>
              </li>
            </ul>
          </div>
        </div>

        <div className="widget widget_nav_menu">
          <h6>{t("help")}</h6>
          <div className="menu-product-help-container">
            <ul id="menu-product-help" className="menu">
              <li
                id="menu-item-129"
                className="menu-item menu-item-type-custom menu-item-object-custom menu-item-129"
              >
                <a href="#support">FAQ</a>
              </li>
              <li
                id="menu-item-13"
                className="menu-item menu-item-type-custom menu-item-object-custom menu-item-13"
              >
                <a href="#testimonials">{t("comment")}</a>
              </li>
              <li
                id="menu-item-14"
                className="menu-item menu-item-type-custom menu-item-object-custom menu-item-14"
              >
                <a
                  href="https://rodolphe37.github.io/my-meditation-time-userguide/"
                  target="new"
                >
                  {t("guide")}
                </a>
              </li>
              <li
                id="menu-item-15"
                className="menu-item menu-item-type-custom menu-item-object-custom menu-item-15"
              >
                <a
                  href="https://www.facebook.com/meditationwithamanohilot/"
                  target="new"
                >
                  Facebook
                </a>
              </li>
            </ul>
          </div>
        </div>

        <div className="widget_text widget widget_custom_html downloadWidget">
          <h6>Installation</h6>
          <div className="textwidget custom-html-widget">
            <div className="button-store-footer">
              <a
                href="#home"
                className="custom-btn d-inline-flex align-items-center m-2 m-sm-0 mb-sm-3"
              >
                <i className="fab fa-google-play"></i>
                <p>
                  <em>{t("comeFooter")}</em>Google Play
                </p>
              </a>
              {isMobile ? (
                <a
                  className="custom-btn  d-inline-flex align-items-center m-2 m-sm-0 "
                  href="https://my-meditation-time.fr/"
                  target="new"
                >
                  <i className="fas fa-globe"></i>
                  <p>
                    <em>{t("installButton")}</em>
                    {t("webButton")}
                  </p>
                </a>
              ) : (
                <button
                  style={{ background: "transparent" }}
                  className="custom-btn  d-inline-flex align-items-center m-2 m-sm-0 "
                  onClick={handleQrcodeShare}
                >
                  <i className="fas fa-globe"></i>
                  <p>
                    <em>{t("installButton")}</em>
                    {t("qrcodeButton")}
                  </p>
                </button>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="footer-copyright">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <p className="copyright text-center">
                Copyright © 2021{" "}
                <a href="https://rodolphe-augusto.fr" target="new">
                  My Meditation Time
                </a>
                . Tous droits réservés.{" "}
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

import "./androidLogo-style.css";

const AndroidLogo = () => {
  return (
    <div className="androidLogoContainer heartbeat">
      <div className="android-logo">
        <ul className="andy">
          <li className="head anim-head">
            <ul className="antennas">
              <li className="left-antenna anim-l-ant"></li>
              <li className="right-antenna anim-r-ant"></li>
            </ul>
            <ul className="eyes anim-eyes">
              <li className="left-eye anim-l-eye"></li>
              <li className="right-eye anim-r-eye"></li>
            </ul>
          </li>
          <li className="body">
            <ul className="arms anim-arms">
              <li className="left-arm anim-l-arm"></li>
              <li className="right-arm anim-r-arm"></li>
            </ul>
            <ul className="legs">
              <li className="left-leg anim-l-leg"></li>
              <li className="right-leg anim-r-leg"></li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default AndroidLogo;

import { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import "./support-style.css";

const Support = () => {
  const { t, i18n } = useTranslation();
  // eslint-disable-next-line no-unused-vars
  const [clickedTab, setClickedTab] = useState();
  const [idClicked, setIdClicked] = useState(null);

  const handleClickedTab = () => {
    setClickedTab((clickedTab) => !clickedTab);
  };

  const Questions = [
    {
      id: 0,
      title: `${t("question1")}`,
      content: `${t("questionText1")}`,
      colapsed: false,
    },
    {
      id: 1,
      title: `${t("question2")}`,
      content: `${t("questionText2")}`,
      colapsed: false,
    },
    {
      id: 2,
      title: `${t("question3")}`,
      content: `${t("questionText3")}`,
      colapsed: false,
    },
    {
      id: 3,
      title: `${t("question4")}`,
      content: `${t("questionText4")}`,
      colapsed: false,
    },
    {
      id: 4,
      title: `${t("question5")}`,
      content: `${t("questionText5")}`,
      colapsed: false,
    },
  ];
  return (
    <section id="support" className="vc_section">
      <div className="container-support">
        <div className="section-title text-center  ">
          <h3>{t("questionsTitle")}</h3>
          <p>{t("questionsSub")}</p>
        </div>

        <div className=" sectionAccordion">
          <div className="accordion" id="accordion1" role="tablist">
            {Questions.map((res) => (
              <div key={res.id} className="card">
                <div
                  onClick={() => setIdClicked(res.id)}
                  className="card-header"
                  role="tab"
                  id="heading-1"
                >
                  <h5 className={idClicked === res.id ? "purpleColor" : ""}>
                    {res.title}
                  </h5>
                  {idClicked === res.id ? (
                    <i
                      onClick={handleClickedTab}
                      style={{ color: "#5f36bb", fontSize: "1.1rem" }}
                      className="fas fa-chevron-up"
                    ></i>
                  ) : (
                    <i
                      onClick={handleClickedTab}
                      style={idClicked === res.id ? {} : { fontSize: "1.1rem" }}
                      className="fas fa-chevron-down"
                    ></i>
                  )}
                </div>
                <div
                  id="collapse-1"
                  className={
                    idClicked === res.id ? "collapse show" : "collapse"
                  }
                  role="tabpanel"
                  aria-labelledby="heading-1"
                  data-parent="#accordion1"
                >
                  <div className="card-body">
                    {res.content.includes("#") ? (
                      <Fragment>
                        <p>{res.content.slice(0, -1)}</p>
                        <a
                          target="new"
                          href={
                            i18n.language === "fr"
                              ? "https://rodolphe37.github.io/my-meditation-time-userguide/#installer-lapplication"
                              : "https://rodolphe37.github.io/my-meditation-time-userguide/README_US.html#install-the-application"
                          }
                        >
                          {t("installStep")}
                        </a>
                      </Fragment>
                    ) : (
                      <p>{res.content}</p>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="vc_row wpb_row vc_row-fluid">
          <div className="wpb_column vc_column_container vc_col-sm-12">
            <div className="vc_column-inner">
              <div className="wpb_wrapper">
                <div className="vc_empty_space" style={{ height: "30px" }}>
                  <span className="vc_empty_space_inner"></span>
                </div>
                <div className="wpb_text_column wpb_content_element  mb-0">
                  <div className="wpb_wrapper">
                    <p style={{ textAlign: "center" }}>
                      {t("morequestion")}{" "}
                      <a href="#contact">
                        <strong>{t("questionhere")}</strong>
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Support;
